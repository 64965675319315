import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import { reportError } from '@this/lib/bugsnag';
import type Department from '../../../../../../../domain/department/department';

type Props = {
  departments: Department[];
  onSelect: (department: Department) => void;
  disabledAddress: boolean;
  workflowFormWidth: string;
};

type State = {
  filteredDepartments: Department[];
};

class DepartmentsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      filteredDepartments: this.props.departments
    };
  }

  componentDidMount() {
    const el = document.getElementById('departments-list-input');
    if (el) el.focus();
  }

  handleSelect = (department: Department) => () => {
    this.props.onSelect(department);
  };

  handleSearchDepartments = (e: any) => {
    const keyword = e.target.value;
    const filtered = this.props.departments.filter(
      department => department.code.indexOf(keyword) >= 0 || department.name.indexOf(keyword) >= 0
    );
    return this.setState({ filteredDepartments: filtered });
  };

  render() {
    try {
      return (
        <DepartmentsListItemLists>
          <DepartmentListsInput
            type="text"
            id="departments-list-input"
            defaultValue=""
            placeholder="検索"
            onKeyUp={this.handleSearchDepartments}
          />
          <DepartmentsListUl workflowFormWidth={this.props.workflowFormWidth}>
            {this.state.filteredDepartments.map((department, i) =>
              department.noAddress() && this.props.disabledAddress ? (
                <DepartmentsListItemDisabled key={i}>
                  <DepartmentsListItemInfo>
                    <p>{department.description()}</p>
                  </DepartmentsListItemInfo>
                </DepartmentsListItemDisabled>
              ) : (
                <DepartmentsListItemEnabled key={i} onClick={this.handleSelect(department)}>
                  <DepartmentsListItemInfo>
                    <p>
                      {this.props.disabledAddress ? department.description() : department.descriptionWithCode()}
                    </p>
                  </DepartmentsListItemInfo>
                </DepartmentsListItemEnabled>
              )
            )}
          </DepartmentsListUl>
        </DepartmentsListItemLists>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const DepartmentsListItemLists = styled.div`
  margin-top: 5px;
`;

const DepartmentListsInput = styled.input`
  &&& {
    width: 100%;
    margin-bottom: 0;
    border-top: none;
  }
`;

const DepartmentsListUl = styled.ul<{ workflowFormWidth?: string }>`
  overflow-y: scroll;
  max-height: 150px;
  width: ${props => props.workflowFormWidth || '420px'};
  border-bottom: 2px solid ${props => props.theme.grayBorderColor};
`;

const DepartmentsListItemDisabled = styled.li`
  background: ${props => props.theme.fieldBgColor};
  min-height: 30px;
  max-height: 60px;
  width: 100%;
  line-height: 30px;
  padding: 0 10px;
  background: #eee;
  cursor: default;
  overflow-x: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 2px solid ${props => props.theme.grayBorderColor};
  border-top: none;
  z-index: 100;
`;

const DepartmentsListItemEnabled = styled.li`
  background: ${props => props.theme.fieldBgColor};
  min-height: 30px;
  max-height: 60px;
  width: 100%;
  line-height: 30px;
  padding:  0 10px
  cursor: pointer;
  overflow-x: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 2px solid ${props => props.theme.grayBorderColor};
  border-top: none;
  z-index: 100;
`;

const DepartmentsListItemInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default DepartmentsList;
