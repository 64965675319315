// import * as React from 'react';
import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@this/constants/themes';
import type { RouteComponentProps } from 'react-router-dom';
import {
  OrganizationTitle,
  OrganizationTable,
  OrganizationTd,
  OrganizationTh,
  OrganizationBody,
  AddButton,
  OrganizationTdButton,
  BulkActionLink
} from '@this/components/organization/organization.style';
import { ButtonType } from '@this/shared/atoms/button';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { ExicCard } from '@this/domain/exic_card';
import ExicCardForm from '@this/components/organization/exic_cards/exic_cards_form';
import { MasterListContainer } from '@this/components/organization/master_list_container';
import { WithConfirmModal } from '@this/shared/ui/feedbacks/modal/confirm_modal';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Fetcher, HTTPError } from '@this/src/util';
import Notification from '../../../notification';

type Props = { availableOptions: string[] } & RouteComponentProps;

interface ExicCardListResponse {
  exic_cards: ExicCard[];
  total_page: number;
}

const ExicCards: React.FC<Props> = () => {
  const [exicCards, setExicCards] = useState<ExicCard[]>([]);
  const [loading, setLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editingTarget, setEditingTarget] = useState<ExicCard | null>();
  const [totalPage, setTotalPage] = useState(1);

  const fetchExicCardList = useCallback(async (page = 1) => {
    Fetcher.get<ExicCardListResponse>('/organization/exic_cards.json', { page })
      .then(res => {
        setExicCards(res.exic_cards.map(card => new ExicCard(card)));
        setTotalPage(res.total_page);
        setLoading(false);
      })
      .catch(error => {
        const err =
          error instanceof HTTPError && error.response?.data.errors
            ? error.response?.data.errors
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        Notification.error(err);
      });
  }, []);

  useEffect(() => {
    fetchExicCardList();
  }, [fetchExicCardList]);

  const openCreateModal = () => {
    setEditingTarget(new ExicCard());
    setIsOpenModal(true);
  };

  const openEditModal = (exicCard: ExicCard) => {
    setEditingTarget(exicCard);
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setEditingTarget(null);
    setIsOpenModal(false);
    fetchExicCardList();
  };

  const handleSubmit = async (exicCard: ExicCard) => {
    if (exicCard.id === 0) {
      await onSubmitCreate(exicCard);
    } else {
      await onSubmitEdit(exicCard);
    }
  };

  const onSubmitCreate = async (exicCard: ExicCard) => {
    Fetcher.post('/organization/exic_cards.json', exicCard?.submitParams() ?? {})
      .then(res => {
        closeModal();
        Notification.success('作成完了しました');
      })
      .catch(error => {
        const err =
          error instanceof HTTPError && error.response?.data.errors
            ? error.response?.data.errors
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        Notification.error(err);
      });
  };

  const onSubmitEdit = async (exicCard: ExicCard) => {
    Fetcher.put(`/organization/exic_cards/${exicCard.id}.json`, exicCard?.submitParams() ?? {})
      .then(res => {
        closeModal();
        Notification.success('編集完了しました');
      })
      .catch(error => {
        const err =
          error instanceof HTTPError && error.response?.data.errors
            ? error.response?.data.errors
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        Notification.error(err);
      });
  };

  const onSubmitDelete = async ({ id }: ExicCard) => {
    Fetcher.delete(`/organization/exic_cards/${id}.json`, {})
      .then(() => {
        fetchExicCardList();
        Notification.success('EXICを削除しました');
      })
      .catch(error => {
        const err =
          error instanceof HTTPError && error.response?.data.errors
            ? error.response?.data.errors
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        Notification.error(err);
      });
  };

  const [passwordVisibility, setPasswordVisibility] = useState<Record<number, boolean>>(() =>
    Object.fromEntries(exicCards.map(card => [card.id, false]))
  );

  const togglePasswordVisibility = (id: number) => {
    setPasswordVisibility(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  return (
    <>
      <OrganizationTitle>EXICマスタ</OrganizationTitle>
      <OrganizationBody>
        {loading ? (
          <SimpleLoading />
        ) : (
          <MasterListContainer
            totalPage={totalPage}
            onPaginate={page => fetchExicCardList(page)}
            createButton={<AddButton onClick={openCreateModal}>新規登録</AddButton>}
            headerContent={
              <div>
                <BulkActionLink to="/organization/exic_cards/csv/bulk_upsert">一括追加・更新</BulkActionLink>
              </div>
            }
            renderContent={() => (
              <OrganizationTable>
                <thead>
                  <tr>
                    <OrganizationTh width="200px">EXIC会員ID</OrganizationTh>
                    <OrganizationTh width="200px">EXICパスワード</OrganizationTh>
                    <OrganizationTh>ユーザー</OrganizationTh>
                    <OrganizationTh width="100px">編集</OrganizationTh>
                    <OrganizationTh width="100px">削除</OrganizationTh>
                  </tr>
                </thead>
                <tbody>
                  {exicCards.map((data: ExicCard, i: number) => (
                    <tr key={i} data-e2e-id={data.id}>
                      <OrganizationTd>{data.exicNumber}</OrganizationTd>
                      <OrganizationTd>
                        <PasswordCell>
                          <span>{passwordVisibility[data.id] ? data.password : '••••••••'}</span>
                          <VisibilityToggle onClick={() => togglePasswordVisibility(data.id)}>
                            {passwordVisibility[data.id] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </VisibilityToggle>
                        </PasswordCell>
                      </OrganizationTd>
                      <OrganizationTd>
                        {data.users.map(user => (
                          <div key={user.email}>
                            {user.name} ({user.email})
                          </div>
                        ))}
                      </OrganizationTd>
                      <OrganizationTd>
                        <OrganizationTdButton onClick={() => openEditModal(data)}>編集</OrganizationTdButton>
                      </OrganizationTd>
                      <OrganizationTd>
                        <WithConfirmModal>
                          {({ open }) => (
                            <OrganizationTdButton
                              buttonType={ButtonType.DANGER}
                              onClick={async () => {
                                const { result } = await open({
                                  description: [
                                    `「${data.exicNumber}」を削除してよろしいですか？`,
                                    `このカードに紐付くユーザーの紐付けは解除されます。`,
                                    `※この操作は取り消せません`
                                  ],
                                  color: 'danger',
                                  submitButtonLabel: '削除'
                                });
                                if (result) {
                                  onSubmitDelete(data);
                                }
                              }}
                            >
                              削除
                            </OrganizationTdButton>
                          )}
                        </WithConfirmModal>
                      </OrganizationTd>
                    </tr>
                  ))}
                </tbody>
              </OrganizationTable>
            )}
          />
        )}
      </OrganizationBody>
      <ExicCardForm open={isOpenModal} exicCard={editingTarget} onAbort={closeModal} onSubmit={handleSubmit} />
    </>
  );
};

const PasswordCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const VisibilityToggle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export default ExicCards;
