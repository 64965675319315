import { Fetcher } from '@this/src/util';
import React, { useCallback, useState } from 'react';
import { styled } from '@this/constants/themes';
import A from '@this/shared/atoms/a';
import Tooltip from '@this/shared/tooltip/tooltip';
import type { HotelJson } from '@this/domain/select_repository';
import Hotel from '@this/domain/hotel/hotel';
import _ from 'lodash';
import MarginType from '@this/domain/organization/margin_type2';
import { useSwitchText } from '@this/lib/hooks/useSwitchingText';
import type HotelStayPlan from '@this/domain/hotel/hotel_stay_plan';
import HotelDetail, {
  HotelBox,
  HotelImageArea,
  HotelContentArea,
  Left,
  HotelName,
  HotelBody,
  Right,
  Note,
  Rating
} from './hotel_box_parts';
import { Yen } from '../shared/box_parts';
import type { SelectHotelBoxProps } from './types';

export interface HotelResponse {
  hotel: HotelJson;
  show_fee: boolean;
  margin_type: MarginType;
  is_domestic: boolean;
  user: {
    show_foreign_hotel_of_over_limit: boolean;
    show_hotel_of_over_limit: boolean;
  };
}

interface Props extends SelectHotelBoxProps {
  showCloseButton?: boolean;
  searchQueryId: number | null;
  onClose?: () => void;
}

const HotelCandidateBox = ({
  destLocation,
  me,
  user,
  hotel,
  inList,
  hotels,
  selected,
  serviceId,
  hotelPriceLimit,
  searchQueryId,
  handleSelect,
  showCloseButton,
  onClose
}: Props) => {
  const [isOverLimit, setIsOverHotelPriceLimit] = useState(false);
  const isOverHotelPriceLimit = isOverLimit || hotel.isOverLimit;
  const loadingText = useSwitchText('hotel', hotel.loading);

  const handleChangeHotelStayPlan = useCallback(
    (hotelJson: HotelJson, selectedPlan: HotelStayPlan, showFee: boolean, marginType: MarginType | undefined) => {
      const includeTax = hotels.includeTax;
      const updatedHotel = new Hotel(
        _.merge(hotelJson, {
          plan_name: selectedPlan.name,
          selected_stay_plan_id: selectedPlan.id,
          room_type: selectedPlan.room_name || selectedPlan.room_type,
          price: selectedPlan.total_fee,
          bed_types: selectedPlan.bed_types,
          average_price: selectedPlan.average_price,
          average_price_with_tax: selectedPlan.average_price_with_tax,
          includeTax,
          showFee,
          marginType,
          walkminute: hotel.walkminute,
          loading: false
        })
      );

      hotels.updateHotelList(updatedHotel);

      if (handleSelect) {
        handleSelect(updatedHotel);
      }

      app.render();
    },
    [handleSelect, hotel, hotel.loading]
  );

  const handleChange = useCallback(() => {
    hotel.setHotelLoading(true);
    Fetcher.get<HotelResponse>(`/hotels/${hotel.id}?search_query_id=${searchQueryId}`).then(
      result => {
        if (result.hotel.sold_out) {
          hotel.setSoldOut(true);
        } else if (result.hotel.too_late) {
          hotel.setTooLate(true);
        } else if (handleSelect) {
          // 利用可能なホテルの情報が取得できた場合。
          handleSelect(hotel);
          let marginType: MarginType | undefined;
          if (result.margin_type) {
            marginType = new MarginType(result.margin_type);
          }
          const includeTax = hotels.includeTax;
          const updatedHotel = new Hotel(
            _.merge(result.hotel, {
              includeTax,
              showFee: result.show_fee,
              marginType,
              walkminute: hotel.walkminute,
              loading: false
            })
          );

          // 楽天スタティックファイルのホテルの場合のみ規定金額チェックを行う
          if (updatedHotel.operator_class_name === 'RakutenStaticfileOperator') {
            if (
              (result.is_domestic && !result.user.show_hotel_of_over_limit) ||
              (!result.is_domestic && !result.user.show_foreign_hotel_of_over_limit)
            ) {
              // 規定金額超過ホテル表示フラグがオフの場合
              const limit = hotelPriceLimit || 0;
              const isOverLimit = limit > 0 && updatedHotel.getAveragePrice()! > limit;
              if (!isOverLimit) {
                handleSelect(hotel);
              }
              setIsOverHotelPriceLimit(isOverLimit);
            } else {
              // 規定金額超過ホテル表示フラグがオンであれば、規定金額超過にかかわらずホテル選択する
              handleSelect(hotel);
            }
          } else {
            handleSelect(hotel);
          }

          hotels.updateHotelList(updatedHotel);
        }
        hotel.setHotelLoading(false);
      },
      e => {
        hotel.setHotelLoading(false);
      }
    );
  }, [handleSelect, hotel, hotel.loading]);

  return (
    <HotelBox selected={selected}>
      <HotelImageArea hotel={hotel} />
      <HotelContentArea>
        <Left>
          <HotelName hotel={hotel} inList={inList} />
          <HotelBody>
            <HotelDetail
              destLocation={destLocation}
              me={me}
              inList={inList}
              user={user}
              hotel={hotel}
              hotels={hotels}
              hotelPriceLimit={hotelPriceLimit}
              searchQueryId={searchQueryId}
              serviceId={serviceId}
              handleChangeHotelStayPlan={handleChangeHotelStayPlan}
              submitButtonLabel="ホテル・プランを選択する"
            />
          </HotelBody>
          {hotel.isForeign() && (hotel.rated() ? <Rating rating={hotel.rating!} /> : <p>評価なし</p>)}
        </Left>
        <Right>
          {hotel.loading ? (
            <LoadingButton>
              <LoadingButtonWrap>
                <ButtonBody>
                  <img src="/images/loading.gif" width={20} height={20} alt="loading" />
                  <div>{loadingText}</div>
                </ButtonBody>
              </LoadingButtonWrap>
            </LoadingButton>
          ) : (
            <>
              {showCloseButton && <CloseButton onClick={onClose}>閉じる</CloseButton>}
              {hotel.package_type ? (
                <Button
                  onClick={handleChange}
                  onTouchStart={hotel.handleTouched}
                  onMouseOver={hotel.setHovered(true)}
                  onMouseLeave={hotel.setHovered(false)}
                >
                  {hotel.hovered && !selected && !hotel.sold_out && !isOverHotelPriceLimit ? (
                    <SelectedButton />
                  ) : (
                    <ButtonWrap
                      className={
                        selected
                          ? 'selected'
                          : hotel.sold_out || hotel.too_late || isOverHotelPriceLimit
                          ? 'disabled'
                          : ''
                      }
                    >
                      <ButtonBody>
                        {(() => {
                          if (hotel.sold_out) {
                            return '空室なし';
                          }
                          if (hotel.too_late) {
                            return '期限を過ぎています';
                          }
                          if (isOverHotelPriceLimit) {
                            return '規定金額以内のお部屋がなくなりました';
                          }

                          return (
                            <>
                              <PeopleNum key={0}>1名</PeopleNum>
                              <Price key={1}>
                                <span data-wovn-ignore>{utils.digits(hotel.package_base_price || 0)}</span>
                                <Yen>円</Yen>
                              </Price>
                            </>
                          );
                        })()}
                      </ButtonBody>
                    </ButtonWrap>
                  )}
                </Button>
              ) : (
                <>
                  <Note hotel={hotel} hotelPriceLimit={hotelPriceLimit} />
                  <TooltipWrap>
                    <Tooltip type="info" place="left">
                      <div className="select__tooltip-format">
                        {hotel
                          .averagePriceTDetailText()
                          .split('\n')
                          .map((l: string, i: number) => (
                            <p key={i}>{l}</p>
                          ))}
                      </div>
                    </Tooltip>
                  </TooltipWrap>
                  <Button
                    onClick={handleChange}
                    onTouchStart={hotel.handleTouched}
                    onMouseOver={hotel.setHovered(true)}
                    onMouseLeave={hotel.setHovered(false)}
                  >
                    {hotel.hovered && !selected ? (
                      <SelectedButton />
                    ) : (
                      <ButtonWrap
                        className={
                          selected
                            ? 'selected'
                            : hotel.sold_out || hotel.too_late || isOverHotelPriceLimit
                            ? 'disabled'
                            : ''
                        }
                      >
                        <ButtonBody>
                          {(() => {
                            if (hotel.sold_out) {
                              return '空室なし';
                            }
                            if (hotel.too_late) {
                              return '期限を過ぎています';
                            }
                            if (isOverHotelPriceLimit) {
                              return '規定金額以内のお部屋がなくなりました';
                            }

                            return (
                              <>
                                <PeopleNum key={0}>1泊1室</PeopleNum>
                                <Price key={1}>
                                  <span data-wovn-ignore>{utils.digits(hotel.getAveragePrice() || 0)}</span>
                                  <Yen>円</Yen>
                                </Price>
                              </>
                            );
                          })()}
                        </ButtonBody>
                      </ButtonWrap>
                    )}
                  </Button>
                </>
              )}
            </>
          )}
        </Right>
      </HotelContentArea>
    </HotelBox>
  );
};

const Button = styled.div`
  width: 135px;
  height: 48px;
  right: 0;
`;

const ButtonWrap = styled.div`
  display: flex;
  border: 2px solid ${props => props.theme.linkColor};
  border-radius: 3px;
  padding: 12px;
  color: ${props => props.theme.linkColor};
  font-weight: bold;
  cursor: pointer;
  background: white;
  text-align: center;

  &.selected {
    background: ${props => props.theme.linkColor};
    color: white;
  }

  &.disabled {
    border-color: ${props => props.theme.grayBorderColor};
    color: ${props => props.theme.grayTextColor};
  }
`;

const SelectedButtonDiv = styled(ButtonWrap)`
  background: ${props => props.theme.accentColor};
  color: white;
`;

const LoadingButton = styled(Button)`
  width: 165px;
`;

const LoadingButtonWrap = styled(ButtonWrap)`
  padding: 5px;
  letter-spacing: 0;
  color: #828282;
`;

const SelectedButton = () => (
  <SelectedButtonDiv>
    <ButtonBody>
      <Price>選択する</Price>
    </ButtonBody>
  </SelectedButtonDiv>
);

const ButtonBody = styled.div`
  margin: 0 auto;
`;

// 地図用
const CloseButton = styled(A)`
  display: block;
  margin: 7px 0 auto;
  font-size: 12px;
  text-align: right;
`;

const PeopleNum = styled.span`
  font-size: 10px;
  margin-right: 10px;
  font-weight: normal;
`;

const Price = styled.span`
  font-size: 14px;
`;

const TooltipWrap = styled.div`
  position: absolute;
  bottom: 55px;
  right: 5px;
`;

export default HotelCandidateBox;
