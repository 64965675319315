import { Fetcher } from '@this/src/util';
import React from 'react';
import _ from 'lodash';

import type { AsyncWorker } from '@this/src/lib/async_queue';
import { AsyncQueue, asyncWorkers } from '@this/src/lib/async_queue';
import Hotel from '@this/domain/hotel/hotel';
import MarginType from '@this/src/domain/organization/margin_type2';
import type User from '@this/domain/user/user';
import { reportError } from '@this/lib/bugsnag';
import HotelCandidateBox from '../../hotel_box/hotel_candidate_box';
import type { HotelResponse } from '../../hotel_box/hotel_candidate_box';
import type HotelList from '../../../../../domain/hotel/hotel_list';

interface HotelListProps {
  destLocation?: { lat: number; lng: number } | null;
  me: User | null;
  onChange: () => void;
  hotels: HotelList;
  hotelPriceLimit: number | null;
  searchQueryId: number | null;
  user: any;
}

interface State {
  queue: AsyncQueue;
  workers: AsyncWorker[];
}

class SelectHotelList extends React.Component<HotelListProps, State> {
  constructor(props: HotelListProps) {
    super(props);

    const queue = new AsyncQueue(20);
    this.state = { queue, workers: [] };
  }

  componentDidMount() {
    const hotelSortKey = localStorage.getItem('hotelSortKey');
    if (hotelSortKey) this.props.hotels.sortBy(hotelSortKey);

    this.fetchHotelPrices();
  }

  componentDidUpdate(prevProps: Readonly<HotelListProps>): void {
    if (this.props.searchQueryId !== prevProps.searchQueryId) {
      this.fetchHotelPrices();
    }
  }

  handleSelect = (hotel: Hotel) => {
    this.props.onChange();
    if (hotel.id) this.props.hotels.selectWithAnimation(hotel.id);
  };

  fetchHotelPrices() {
    // StaticFile利用時のみ、一括で価格の更新を行いたい
    // https://aitravel.atlassian.net/browse/AITRAVEL-3437
    if (!this.props.hotels.filteredList.some(h => h.operator_class_name === 'RakutenStaticfileOperator')) return;

    const workers = asyncWorkers(this.state.queue);
    this.setState({ workers });

    this.props.hotels.filteredList.forEach(hotel => {
      if (hotel.operator_class_name !== 'RakutenStaticfileOperator') return;

      hotel.setHotelLoading(true);

      this.state.queue.enqueue(next => {
        Fetcher.get<HotelResponse>(
          `/hotels/${hotel.id}/rakuten_static_hotel_price/?search_query_id=${this.props.searchQueryId}`
        ).then(
          result => {
            if (result.hotel.sold_out) {
              hotel.setSoldOut(true);
            } else if (result.hotel.too_late) {
              hotel.setTooLate(true);
            } else {
              let marginType: MarginType | undefined;
              if (result.margin_type) {
                marginType = new MarginType(result.margin_type);
              }
              const includeTax = this.props.hotels.includeTax;
              const updatedHotel = new Hotel(
                _.merge(result.hotel, {
                  includeTax,
                  showFee: result.show_fee,
                  marginType,
                  walkminute: hotel.walkminute,
                  loading: false
                })
              );

              if (
                (result.is_domestic && !result.user.show_hotel_of_over_limit) ||
                (!result.is_domestic && !result.user.show_foreign_hotel_of_over_limit)
              ) {
                // 規定金額超過ホテル表示フラグがオフの場合
                const limit = this.props.hotelPriceLimit || 0;
                const isOverLimit = limit > 0 && updatedHotel.getAveragePrice()! > limit;
                updatedHotel.setIsOverLimit(isOverLimit);
              }

              this.props.hotels.updateHotelList(updatedHotel);
            }

            hotel.setHotelLoading(false);
            next();
          },
          e => {
            hotel.setHotelLoading(false);
          }
        );
      });
    });

    this.state.queue.enqueue(() => {
      this.state.workers.forEach(worker => {
        worker.isContinue = false;
      });
      this.setState({ workers: [] });
    });
  }

  render() {
    try {
      return (
        <div className="select-hotel-list">
          {this.props.hotels.filteredList.map((h, i) => (
            <HotelCandidateBox
              destLocation={this.props.destLocation}
              me={this.props.me}
              user={this.props.user}
              key={i}
              hotel={h}
              hotels={this.props.hotels}
              inList
              hotelPriceLimit={this.props.hotelPriceLimit}
              searchQueryId={this.props.searchQueryId}
              selected={this.props.hotels.currentId === h.id}
              handleSelect={this.handleSelect}
            />
          ))}
        </div>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export default SelectHotelList;
