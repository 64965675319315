import moment from 'moment-timezone';
import { formatPrice } from '@this/src/util/price';
import { zenToHan } from '@this/src/util';

export type ShareholderTicketArgs = {
  id?: number;
  status?: string;
  company_type?: CompanyType;
  price?: number;
  purchased_at?: string;
  expire_at?: string;
  inactivated_at?: string;
  number?: string;
  password?: string;
  trip_id?: number;
  is_deleted?: boolean;
  remarks?: string;
};

type CompanyType = 0 | 1 | 2;

class ShareholderTicket {
  id: number | undefined;

  status: string | undefined;

  companyType: CompanyType;

  price: number;

  purchasedAt: moment.Moment;

  expireAt: moment.Moment;

  inactivatedAt: moment.Moment | undefined;

  isExpired: boolean;

  number: string;

  password: string;

  tripId: number | undefined;

  isDeleted: boolean | undefined;

  remarks: string;

  constructor(args: ShareholderTicketArgs = {}) {
    this.id = args.id;
    this.status = args.status;
    this.companyType = args.company_type || 0;
    this.price = args.price || 0;
    this.purchasedAt = moment(args.purchased_at).tz('Asia/Tokyo');
    this.expireAt = moment(args.expire_at).tz('Asia/Tokyo');
    if (args.inactivated_at) {
      this.inactivatedAt = moment(args.inactivated_at).tz('Asia/Tokyo');
    }
    this.isExpired = this.expireAt <= moment();
    this.number = args.number || '';
    this.password = args.password || '';
    this.tripId = args.trip_id;
    this.isDeleted = args.is_deleted;
    this.remarks = args.remarks || '';
  }

  companyStr() {
    switch (this.companyType) {
      case 0:
        return 'ANA';
      case 1:
        return 'JAL';
      case 2:
        return 'SFJ';
      default:
        return '';
    }
  }

  summary() {
    return `${this.companyStr()}: ${this.number} (${formatPrice(Number(this.price))})`;
  }

  description() {
    return `\
${this.companyStr()}: ${formatPrice(Number(this.price))}
番号: ${this.number}
パス: ${this.password}
購入日: ${this.purchasedAt.format('YYYY/MM/DD')}
有効期限: ${this.expireAt.format('YYYY/MM/DD')}\
`;
  }

  setCompanyType(value: string) {
    this.companyType = parseInt(value, 10) as CompanyType;
    return app.render();
  }

  setPrice(value: string) {
    this.price = parseInt(this.convertToHalfWidth(value) || '0', 10);
    return app.render();
  }

  sanitizePrice() {
    this.price = parseInt(this.convertToHalfWidth(this.price.toString()) || '0', 10);
    return app.render();
  }

  convertToHalfWidth(int: string) {
    if (int === '') {
      return null;
    }
    return zenToHan(int.toString());
  }

  setPurchasedAt(value: string) {
    this.purchasedAt = moment(value);
    return app.render();
  }

  setExpireAt(value: string) {
    this.expireAt = moment(value);
    return app.render();
  }

  setNumber(value: string) {
    this.number = value;
    return app.render();
  }

  setPassword(value: string) {
    this.password = value;
    return app.render();
  }

  setIsDelete(value: boolean) {
    this.isDeleted = value;
    return app.render();
  }

  setRemarks(value: string) {
    this.remarks = value;
    return app.render();
  }

  updateParams() {
    return {
      company_type: this.companyType,
      price: this.price,
      purchased_at: this.purchasedAt.format('YYYY-MM-DD'),
      expire_at: this.expireAt.format('YYYY-MM-DD'),
      number: this.number,
      password: this.password,
      is_deleted: this.isDeleted,
      remarks: this.remarks
    };
  }
}

export default ShareholderTicket;
