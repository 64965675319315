import _ from 'lodash';
import User from '@this/src/domain/user/user';
import Trip from '@this/src/domain/trip/trip';
import type UserJson from '@this/domain/user/user_json';

export type TripListArgs = {
  showFee?: boolean;
  isInternalNumberRequired?: boolean;
  isProjectNameRequired?: boolean;
  shinkansenUrgent?: boolean[];
  users?: UserJson[];
};

class TripList {
  showFee: boolean;

  isInternalNumberRequired: boolean | undefined;

  isProjectNameRequired: boolean | undefined;

  shinkansenUrgent: boolean[] | null;

  list: Trip[];

  constructor(rawTrips: Trip[] = [], args: TripListArgs = {}) {
    this.showFee = typeof args.showFee === 'undefined' ? true : args.showFee;
    this.isInternalNumberRequired = args.isInternalNumberRequired;
    this.isProjectNameRequired = args.isProjectNameRequired;
    this.shinkansenUrgent = typeof args.shinkansenUrgent === 'undefined' ? null : args.shinkansenUrgent;
    this.list = _.map(rawTrips, (t, index) => {
      const userRaw = _.find(args.users, u => u.id === t.user_id);
      if (userRaw) {
        t.user = new User(userRaw);
      }
      if (this.shinkansenUrgent !== null) {
        return new Trip(
          _.merge(t, {
            showFee: this.showFee,
            shinkansenUrgent: this.shinkansenUrgent[index],
            isInternalNumberRequired: this.isInternalNumberRequired,
            isProjectNameRequired: this.isProjectNameRequired
          })
        );
      }
      return new Trip(
        _.merge(t, {
          showFee: this.showFee,
          isInternalNumberRequired: this.isInternalNumberRequired,
          isProjectNameRequired: this.isProjectNameRequired
        })
      );
    });
    if (!this.list) {
      this.list = [];
    }
  }

  update(rawTrip: Trip, args: TripListArgs = {}) {
    let index = _.findIndex(this.list, t => t.id === rawTrip.id);
    if (index < 0) {
      index = this.list.length;
    }
    const userRaw = _.find(args.users, u => u.id === rawTrip.user_id);
    if (userRaw) {
      rawTrip.user = new User(userRaw);
    }
    if (this.shinkansenUrgent !== null) {
      this.list[index] = new Trip(
        _.merge(rawTrip, {
          showFee: this.showFee,
          shinkansenUrgent: this.shinkansenUrgent[index],
          isInternalNumberRequired: this.isInternalNumberRequired,
          isProjectNameRequired: this.isProjectNameRequired
        })
      );
    } else {
      this.list[index] = new Trip(
        _.merge(rawTrip, {
          showFee: this.showFee,
          isInternalNumberRequired: this.isInternalNumberRequired,
          isProjectNameRequired: this.isProjectNameRequired
        })
      );
    }
    return app.render();
  }

  find(id: number | string) {
    return _.find(this.list, t => t.id === (typeof id === 'string' ? parseInt(id, 10) : id));
  }

  first() {
    return this.list[0];
  }

  firstOngoingTrip() {
    return _.find(this.list, t => t.isOngoing());
  }

  firstArchivedTrip() {
    return _.find(this.list, t => t.isArchived());
  }

  ongoingTrips() {
    return this.list.filter(t => t.isOngoing());
  }

  totalPrice() {
    return _.sum(_.map(this.list, t => t.totalPrice()));
  }
}

export default TripList;
