import { Fetcher } from '@this/src/util';
import React from 'react';
import _ from 'lodash';

type ServiceType = 'biztra';

type TrafficType =
  | 'train'
  | 'train.shinkansen'
  | 'plane'
  | 'ship'
  | 'bus'
  | 'bus.local'
  | 'bus.connection'
  | 'bus.highway'
  | 'bus.midnight';

export interface Props<T extends string> {
  id: T;
  value: string;
  onChange: (funcName: T, value: string, address: string) => void;
  onDisappearSuggest?: (funcName: T) => void;
  label?: string;
  trafficTypes?: TrafficType[];
  serviceType?: ServiceType;
  example: string;
  className?: string;
  freeInput?: boolean;
}

interface Suggest {
  mainText: string;
  subText: string;
  img: string;
}

interface State {
  value: string;
  suggests: Suggest[];
  showSuggest: boolean;
  activeIndex: number | null;
  editing: boolean;
}

interface StationsResponse {
  stations: string[];
}

export default class EkispertInput<T extends string> extends React.Component<Props<T>, State> {
  constructor(props: Props<T>) {
    super(props);

    this.state = {
      value: props.value,
      suggests: [],
      showSuggest: false,
      activeIndex: null,
      editing: false
    };
  }

  getServiceUrl = () => {
    switch (this.props.serviceType) {
      case 'biztra':
        return '/biztra/ekispert/stations';
      default:
        return '/ekisperts/stations.json';
    }
  };

  getParams = (value: string) => {
    return new URLSearchParams(
      Object.fromEntries(
        [
          ['name', value],
          ['type', this.props.trafficTypes?.join(':')]
        ].filter(([_, value]) => value)
      )
    );
  };

  fetchSuggests = async () => {
    const value = this.state.value;
    if (!value) return;
    const res = await Fetcher.get<StationsResponse>(`${this.getServiceUrl()}?${this.getParams(value)}`);
    const suggests: Suggest[] = res.stations.map(s => {
      return {
        mainText: s,
        subText: '',
        img: 'map.png'
      };
    });
    this.setState({
      suggests,
      activeIndex: null
    });
  };

  updateSuggests = _.throttle(this.fetchSuggests, 500, { trailing: true });

  handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (this.props.freeInput) {
      this.setValue(e.target.value);
      this.setState(
        {
          showSuggest: true,
          activeIndex: null
        },
        () => this.updateSuggests()
      );
    } else {
      this.setState(
        {
          value: e.target.value,
          showSuggest: true,
          activeIndex: null
        },
        () => this.updateSuggests()
      );
    }
  };

  handleFocus = () => {
    this.setState({
      editing: true,
      showSuggest: true
    });
  };

  handleBlur = () => {
    const activeItem = this.getActiveItem();
    const idx = this.state.suggests.map(s => s.mainText).indexOf(this.state.value);

    if (activeItem) {
      this.setValue(activeItem.mainText, activeItem.subText);
    } else if (idx > -1) {
      this.setValue(this.state.suggests[idx].mainText);
    } else if (this.state.value !== this.props.value) {
      this.setValue('');
    }

    if (this.props.value.length === 0) this.setState({ editing: false });
    this.handleDisappearSuggest(null);
  };

  handleInputKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const ARROW_UP = 38;
    const ARROW_DOWN = 40;
    const ENTER_KEY = 13;
    const ESC_KEY = 27;
    const TAB_KEY = 9;

    switch (evt.keyCode) {
      case ENTER_KEY: {
        if (this.state.showSuggest) {
          evt.preventDefault();
          const activeItem = this.getActiveItem();
          if (activeItem) {
            this.setValue(activeItem.mainText, activeItem.subText);
          }
          this.handleDisappearSuggest(this.state.activeIndex);
        }
        break;
      }
      case ARROW_DOWN: {
        evt.preventDefault();
        const activeItem = this.getActiveItem();
        if (!activeItem) {
          this.setActiveItemAtIndex(0);
        } else {
          const nextIndex = (this.state.activeIndex! + 1) % this.state.suggests.length;
          this.setActiveItemAtIndex(nextIndex);
        }
        break;
      }
      case ARROW_UP: {
        evt.preventDefault();
        const i = this.state.activeIndex;
        const prevIndex = i ? i - 1 : this.state.suggests.length - 1;
        this.setActiveItemAtIndex(prevIndex);
        break;
      }
      case ESC_KEY: {
        this.handleDisappearSuggest(null);
        break;
      }
      case TAB_KEY: {
        const idx = this.state.suggests.map(s => s.mainText).indexOf(this.state.value);
        if (idx > -1) this.setValue(this.state.suggests[idx].mainText);
        break;
      }
      default:
        break;
    }
  };

  handleClick = (e: React.FocusEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.preventDefault();
    this.setState({ showSuggest: true });
  };

  handleSelectSuggest = (s: Suggest) => {
    this.setValue(s.mainText, s.subText);
    this.handleDisappearSuggest(this.state.activeIndex);
  };

  setActiveItemAtIndex = (index: number) => {
    if (this.state.suggests.length > 0) {
      this.setState({
        activeIndex: index,
        showSuggest: true
      });
    }
  };

  getActiveItem = () => !_.isNull(this.state.activeIndex) && this.state.suggests[this.state.activeIndex];

  private setValue = (value: string, address = '') => {
    this.setState({ value }, () => {
      this.props.onChange(this.props.id, value, address);
    });
  };

  private handleDisappearSuggest = (newActiveIndex: number | null) => {
    const newState = { showSuggest: false, activeIndex: newActiveIndex };
    this.setState(newState, () => {
      if (this.props.onDisappearSuggest) this.props.onDisappearSuggest(this.props.id);
    });
  };

  render() {
    const state = this.state;
    const props = this.props;
    const classBase = props.className || 'form-elements-text-field';
    const highlightClass = (idx: number) => (this.state.activeIndex === idx ? 'highlight' : '');
    return (
      <div className={`auto-completable-input ${classBase}`}>
        <div className={`${classBase}__label`}>{state.editing && props.label}</div>
        <input
          type="text"
          className={`${classBase}__input`}
          id={props.id}
          name={props.id}
          value={state.value}
          placeholder={state.editing ? props.example : props.label}
          onChange={this.handleInput}
          onFocus={this.handleFocus}
          onClick={this.handleClick}
          onBlur={this.handleBlur}
          onKeyDown={this.handleInputKeyDown}
          autoComplete="off"
        />
        {state.showSuggest && (
          <div className="search__suggest-container">
            {state.suggests.map((suggest, i) => (
              <div
                className={`search__suggest-wrapper ${highlightClass(i)}`}
                onMouseDown={() => this.handleSelectSuggest(suggest)}
                onMouseOver={() => this.setActiveItemAtIndex(i)}
                key={i}
                title={`${suggest.mainText} ${suggest.subText}`}
              >
                <span>
                  <img
                    src={`/images/${suggest.img}`}
                    width="15"
                    height="18"
                    alt="place"
                    className="search__suggest-icon"
                  />
                </span>
                <span className="search__suggest-text">{suggest.mainText}</span>
                <span className="search__suggest-subtext">{suggest.subText}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
