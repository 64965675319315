import React from 'react';
import _ from 'lodash';

import type Prefecture from '@this/domain/prefecture';
import { trackView } from '@this/src/util';
import type ReserveInfo from '@this/domain/reserve_info';
import type ReservingTrip from '@this/domain/trip/reserving_trip';
import type ExicCard from '@this/domain/exic_card';
import { reportError } from '@this/lib/bugsnag';
import type { TripDuplicationInterface } from '../../reserve_confirm';
import ConfirmCustomerInformationTemplate from './confirm_customer_information.template';

export type Props = {
  handleSaveDraft?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handlePaymentSubmit: () => void;
  handleAgreementTermsCheck: () => void;

  isCustomerInfoRequired: () => boolean;
  reserveInfo: ReserveInfo;
  reservingTrip: ReservingTrip;
  paymentLoading: boolean;
  paymentFailed: boolean;
  paymentErrors: string | string[];
  termsAgree: boolean;
  prefecture: Prefecture[];
  exicCards: ExicCard[];
  tripDuplications: TripDuplicationInterface[];
  // disconnected; // 渡ってこない
  handleBackToStep1Click: (e?: React.MouseEvent) => void;
};

export type State = {
  exicPasswordTypes: string[];
};

class ConfirmCustomerInformation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      exicPasswordTypes: props.reserveInfo.travelers.list.map(_l => 'password')
    };
  }

  handleConfirmed = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    this.props.handlePaymentSubmit();
  };

  componentDidMount() {
    trackView('/reserve_confirm#2');
  }

  handleExicPasswordType = async (i: number) => {
    if (this.state.exicPasswordTypes[i]) {
      if (this.state.exicPasswordTypes[i] === 'password') {
        this.setState({
          exicPasswordTypes: this.state.exicPasswordTypes.map((value, index) => (index === i ? 'text' : value))
        });
      } else {
        this.setState({
          exicPasswordTypes: this.state.exicPasswordTypes.map((value, index) => (index === i ? 'password' : value))
        });
      }
    }
  };

  render() {
    try {
      return (
        <ConfirmCustomerInformationTemplate
          {...this.props}
          {...this.state}
          handleConfirmed={this.handleConfirmed}
          handleExicPasswordType={this.handleExicPasswordType}
        />
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export default ConfirmCustomerInformation;
