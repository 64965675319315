import { Fetcher } from '@this/src/util';
import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import { reportError } from '@this/lib/bugsnag';
import type { TransitJson, LegSummaryJson, LegJson } from '../../../../domain/select_repository';
import type Transit from '../../../../domain/transit/transit';
import type Segment from '../../../../domain/transit/segment';
import type { Tab } from '../../../../domain/select_store';
import type SelectStore from '../../../../domain/select_store';
import type SearchQueryItem from '../../../../domain/search_query_item';

import SelectTransportBoxShortdistanceTemplate from './transport_box_shortdistance.template';

export interface SelectTransportBoxShortdistanceProps {
  key?: string;
  queryItem?: SearchQueryItem;
  handleClickTab?: (tab: Tab) => void;
  handleSelect?: (t: Transit, opts?: { changeable?: boolean; domesticAirPriceIndex?: number }) => void;
  transport: Transit;
  inList: boolean;
  selected: boolean;
  domesticAirPriceIndex: number;
  tab?: 'outword' | 'homeword' | 'packageOutword' | 'packageHomeword' | number;
  store: SelectStore;
  loading?: boolean;
  direction?: string;
  parentLoading?: (loading: boolean) => void;

  // ArrangementRequestForm
  isRequestForm?: boolean;
}

export interface SelectTransportBoxShortdistanceState {
  loading: boolean;
}

class SelectTransportBoxShortdistance extends React.Component<
  SelectTransportBoxShortdistanceProps,
  SelectTransportBoxShortdistanceState
> {
  constructor(props: SelectTransportBoxShortdistanceProps) {
    super(props);

    this.state = { loading: false };
  }

  async componentDidMount() {
    if (this.props.inList && this.props.selected && this.props.transport.air) {
      await this.replaceLegSummaries();
    }
  }

  handleDomesticAirPriceChange = async (index: number) => {
    if (this.props.parentLoading) {
      this.props.parentLoading(true);
    }
    this.setState({ loading: true });
    await this.replaceLegSummaries();
    if (this.props.handleSelect) this.props.handleSelect(this.props.transport, { domesticAirPriceIndex: index });
    this.setState({ loading: false });
    if (this.props.parentLoading) {
      this.props.parentLoading(false);
    }
  };

  handleChange = async () => {
    if (this.props.parentLoading) {
      this.props.parentLoading(true);
    }
    if (this.props.inList) {
      if (this.props.handleSelect) {
        this.setState({ loading: true });
        await this.props.handleSelect(this.props.transport);
        this.setState({ loading: false });
      }
    } else if (this.props.tab !== undefined) {
      if (this.props.handleClickTab) this.props.handleClickTab(this.props.selected ? 'none' : this.props.tab);
    }
    if (this.props.parentLoading) {
      this.props.parentLoading(false);
    }
  };

  private async replaceLegSummaries() {
    if (this.props.queryItem == null) return;

    const legSummariesAir = SelectTransportBoxShortdistance.legSummariesAirFromTo(
      this.props.transport.segments[0].leg_summaries
    );
    const beforeLegSummary = this.getLegSummary('before');
    if (beforeLegSummary && beforeLegSummary.indexes.length > 0) {
      const shortDeptResultFirst = await SelectTransportBoxShortdistance.requestShortdistanceData(
        this.props.queryItem,
        '',
        legSummariesAir.fromName,
        legSummariesAir.fromTime,
        'arrival',
        true
      );
      if (shortDeptResultFirst[0]?.segments) {
        const segment = this.props.transport.segments[0];
        const firstResultSummaries = shortDeptResultFirst[0].segments[0].leg_summaries;
        const newlegSummaryFromTo = SelectTransportBoxShortdistance.newLegSummaryFromTo(firstResultSummaries);
        const beforeLegSummariIndex = SelectTransportBoxShortdistance.getLegSummaryIndex(segment, 'before');
        const airLegSummariIndex = SelectTransportBoxShortdistance.getLegSummaryIndex(segment, 'air');
        const legSum = segment.leg_summaries[beforeLegSummariIndex];
        legSum.name = newlegSummaryFromTo.name;
        legSum.price = SelectTransportBoxShortdistance.sumLegSummaryPrice(firstResultSummaries);
        legSum.from.name = newlegSummaryFromTo.fromName;
        legSum.from.time = newlegSummaryFromTo.fromTime;
        legSum.to.name = newlegSummaryFromTo.toName;
        legSum.to.time = newlegSummaryFromTo.toTime;
        legSum.indexes = SelectTransportBoxShortdistance.concatLegSummariesIndexes(firstResultSummaries);
        segment.leg_summaries[beforeLegSummariIndex] = legSum;
        const airLegs = this.legsAirOnly();
        const newAirIndexes: string[] = SelectTransportBoxShortdistance.generateNewSummaryIndex(
          firstResultSummaries[firstResultSummaries.length - 1].indexes[
            firstResultSummaries[firstResultSummaries.length - 1].indexes.length - 1
          ],
          airLegs.length
        );
        segment.leg_summaries[airLegSummariIndex].indexes = newAirIndexes;

        const newLeg: LegJson[] = shortDeptResultFirst[0].segments[0].legs.concat(airLegs);
        segment.legs = newLeg;
        this.props.transport.segments[0] = segment;
        this.props.store.needReplaceSegments = true;
        app.render();
      } else {
        const segment = this.props.transport.segments[0];
        const beforeLegSummariIndex = SelectTransportBoxShortdistance.getLegSummaryIndex(segment, 'before');
        segment.leg_summaries.splice(beforeLegSummariIndex, 1);
        this.props.transport.segments[0] = segment;
        this.props.store.needReplaceSegments = true;
        app.render();
      }
    }

    const afterLegSummary = this.getLegSummary('after');
    if (afterLegSummary && afterLegSummary.indexes.length > 0) {
      const shortDeptResultLast = await SelectTransportBoxShortdistance.requestShortdistanceData(
        this.props.queryItem,
        legSummariesAir.toName,
        '',
        legSummariesAir.toTime,
        'departure',
        false
      );
      if (shortDeptResultLast[0]?.segments) {
        const segment = this.props.transport.segments[0];
        const lastResultSummaries = shortDeptResultLast[0].segments[0].leg_summaries;
        const newlegSummaryFromToReturn = SelectTransportBoxShortdistance.newLegSummaryFromTo(lastResultSummaries);
        const afterLegSummariIndex = SelectTransportBoxShortdistance.getLegSummaryIndex(segment, 'after');
        const airLegSummariIndex = SelectTransportBoxShortdistance.getLegSummaryIndex(segment, 'air');
        const legSum = segment.leg_summaries[afterLegSummariIndex];
        legSum.name = newlegSummaryFromToReturn.name;
        legSum.price = SelectTransportBoxShortdistance.sumLegSummaryPrice(lastResultSummaries);
        legSum.from.name = newlegSummaryFromToReturn.fromName;
        legSum.from.time = newlegSummaryFromToReturn.fromTime;
        legSum.to.name = newlegSummaryFromToReturn.toName;
        legSum.to.time = newlegSummaryFromToReturn.toTime;
        legSum.indexes = SelectTransportBoxShortdistance.generateNewSummaryIndex(
          segment.leg_summaries[airLegSummariIndex].indexes[
            segment.leg_summaries[airLegSummariIndex].indexes.length - 1
          ],
          shortDeptResultLast[0].segments[0].legs.length
        );
        segment.leg_summaries[afterLegSummariIndex] = legSum;
        const beforeRouteLegs =
          beforeLegSummary && beforeLegSummary.indexes.length > 0
            ? this.props.transport.segments[0].legs
            : this.legsAirOnly();
        const newLeg: LegJson[] = beforeRouteLegs.concat(shortDeptResultLast[0].segments[0].legs);
        segment.legs = newLeg;
        this.props.transport.segments[0] = segment;
        this.props.store.needReplaceSegments = true;
        app.render();
      } else {
        const segment = this.props.transport.segments[0];
        const afterLegSummariIndex = SelectTransportBoxShortdistance.getLegSummaryIndex(segment, 'after');
        segment.leg_summaries.splice(afterLegSummariIndex, 1);
        this.props.transport.segments[0] = segment;
        this.props.store.needReplaceSegments = true;
        app.render();
      }
    }
  }

  private static async requestShortdistanceData(
    queryItem: SearchQueryItem,
    from: string,
    to: string,
    time: string,
    type: string,
    isFrom: boolean
  ) {
    const params = SelectTransportBoxShortdistance.transitParam(queryItem, from, to, time, type, isFrom);
    const result = await Fetcher.get<TransitJson[]>('transit_shortdistance.json', params);
    return result;
  }

  private static transitParam(
    queryItem: SearchQueryItem,
    from: string,
    to: string,
    time: string,
    type: string,
    isFrom: boolean
  ) {
    const originParam = queryItem.transitParams();
    const sdTime = `${isFrom ? originParam.outdate : originParam.homedate} ${time}`;
    const direction = isFrom ? 'outdate' : 'home';
    return {
      org_lat: isFrom ? originParam.org_lat : queryItem.orgGeocode?.location.lat,
      org_lng: isFrom ? originParam.org_lng : queryItem.orgGeocode?.location.lng,
      dest_lat: !isFrom ? originParam.dest_lat : queryItem.destGeocode?.location.lat,
      dest_lng: !isFrom ? originParam.dest_lng : queryItem.destGeocode?.location.lng,
      outdate: originParam.outdate,
      outhour: originParam.outhour,
      outmin: originParam.outmin,
      outtype: originParam.outtype,
      homedate: originParam.homedate,
      homehour: originParam.homehour,
      homemin: originParam.homemin,
      peoplenum: originParam.peoplenum,
      sd_from: from,
      sd_to: to,
      sd_time: isFrom
        ? moment(sdTime).subtract(30, 'm').format('YYYY-MM-DD HH:mm') // 飛行機乗るまでに30分のバッファ時間を入れる
        : moment(sdTime).add(30, 'm').format('YYYY-MM-DD HH:mm'),
      sd_type: type,
      direction
    };
  }

  private static legSummariesAirFromTo(summaries: LegSummaryJson[]) {
    let fromName = '';
    let fromTime = '';
    let toName = '';
    let toTime = '';
    if (summaries) {
      summaries.forEach(l => {
        if (l.type === 'air') {
          fromName = l.from.name;
          fromTime = l.from.time;
          toName = l.to.name;
          toTime = l.to.time;
        }
      });
    }
    return { fromName, fromTime, toName, toTime };
  }

  private static newLegSummaryFromTo(summaries: LegSummaryJson[]) {
    let fromName = '';
    let fromTime = '';
    let toName = '';
    let toTime = '';
    let name = '';
    if (summaries) {
      summaries.forEach(l => {
        if (l.type === 'before') {
          if (l.from.time !== '') {
            fromName = l.from.name;
            fromTime = l.from.time;
          }
          if (l.to.time !== '') {
            toName = l.to.name;
            toTime = l.to.time;
          }
          if (l.name !== '' && l.name !== null) {
            name = l.name;
          }
        }
      });
      summaries.forEach(l => {
        if (l.type === 'jr') {
          if (fromTime === '' && l.from.time !== '') {
            fromName = l.from.name;
            fromTime = l.from.time;
          }
          if (l.to.time !== '') {
            toName = l.to.name;
            toTime = l.to.time;
          }
          if (l.name !== '' && l.name !== null) {
            name = l.name;
          }
        }
      });
      summaries.forEach(l => {
        if (l.type === 'after') {
          if (fromTime === '' && l.from.time !== '') {
            fromName = l.from.name;
            fromTime = l.from.time;
          }
          if (fromName === '' && l.from.time !== '' && l.from.time === fromTime) {
            fromName = l.from.name;
            fromTime = l.from.time;
          }
          if (l.to.time !== '') {
            toName = l.to.name;
            toTime = l.to.time;
          }
          if (name === '' && l.name !== '' && l.name !== null) {
            name = l.name;
          }
        }
      });
    }
    return { fromName, fromTime, toName, toTime, name };
  }

  private static sumLegSummaryPrice(summaries: LegSummaryJson[]) {
    let price = 0;
    if (summaries) {
      summaries.forEach(l => {
        if (l.price !== null) {
          price += l.price;
        }
      });
    }
    return price;
  }

  private static concatLegSummariesIndexes(summaries: LegSummaryJson[]) {
    let indexes: string[] = [];
    if (summaries) {
      summaries.forEach(l => {
        indexes = indexes.concat(l.indexes);
      });
    }
    return indexes;
  }

  private legsAirOnly() {
    let indexes: string[] = [];
    const retLegs: LegJson[] = [];
    if (this.props.transport.segments[0]) {
      this.props.transport.segments[0].leg_summaries.forEach(l => {
        if (l.type === 'air') {
          indexes = l.indexes;
        }
      });
    }
    indexes.forEach(i => {
      retLegs.push(this.props.transport.segments[0].legs[Number(i) - 1]);
    });
    return retLegs;
  }

  private getLegSummary = (type: string): LegSummaryJson | any => {
    let legsammary: LegSummaryJson | any = null;
    if (
      this.props.transport.segments &&
      this.props.transport.segments[0] &&
      this.props.transport.segments[0].leg_summaries
    ) {
      this.props.transport.segments[0].leg_summaries.forEach(ls => {
        if (ls.type === type) {
          legsammary = ls;
        }
      });
    }
    return legsammary;
  };

  private static getLegSummaryIndex = (segment: Segment, type: string): number => {
    let index = 0;
    if (segment) {
      for (let i = 0; i < segment.leg_summaries.length; i += 1) {
        if (segment.leg_summaries[i].type === type) {
          index = i;
        }
      }
    }
    return index;
  };

  private static generateNewSummaryIndex(lastIdx: string, count: number) {
    const indexes: string[] = [];
    const startIdx = Number(lastIdx) + 1;
    for (let i = 0; i < count; i += 1) {
      indexes.push(String(startIdx + i));
    }
    return indexes;
  }

  render() {
    try {
      return (
        <SelectTransportBoxShortdistanceTemplate
          {...this.props}
          {...this.state}
          handleDomesticAirPriceChange={this.handleDomesticAirPriceChange}
          handleChange={this.handleChange}
        />
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export default SelectTransportBoxShortdistance;
