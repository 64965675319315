import React from 'react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import { reportError } from '@this/lib/bugsnag';
import type Hotel from '../../../../domain/hotel/hotel';

import Text from '../../../shared/text/text';

type Props = {
  hotel: Hotel | null | undefined;
  isPackage: boolean;
  hotelPrice: number;
  peoplenum: number;
  roomnum: number | undefined;
};

class ReserveConfirmHotelTr extends React.Component<Props> {
  render() {
    try {
      const { hotel, isPackage, hotelPrice, peoplenum, roomnum } = this.props;

      return (
        <>
          {hotel ? (
            <>
              <Text text={hotel.name} />
              <TextDetail>
                <span data-wovn-ignore>
                  {hotel.checkin_date} 〜 {hotel.checkout_date}
                </span>
                <br />
                {isPackage ? (
                  `${peoplenum}名/${roomnum}室`
                ) : (
                  <div className="flex">
                    <span data-wovn-ignore>{utils.digits(hotelPrice)}</span>円({peoplenum}名/{roomnum}室)
                  </div>
                )}
                {hotel.mandatory_tax && hotel.mandatory_tax > 0 ? (
                  <p data-wovn-ignore>{utils.digits(hotel.mandatory_tax)}円(現地徴収料)</p>
                ) : null}
                <p>
                  {hotel.stayDays()}泊{hotel.days()}日
                </p>
                <p>チェックイン　：{hotel.checkinText()}</p>
                <p>チェックアウト：{hotel.checkoutText()}</p>
                <p>
                  朝食：
                  {hotel.breakfast == null ? 'お問合せください' : hotel.breakfast ? '有り' : '無し'}
                </p>
                <p>
                  宿泊プラン：
                  {hotel.plan_name || '-'}
                </p>
                {hotel.detail_path && (
                  <p>
                    宿泊詳細情報：
                    <a href={hotel.detail_path} target="_blank" rel="noreferrer noopener">
                      詳細はコチラ
                    </a>
                  </p>
                )}
                {hotel.room_type && (
                  <p>
                    部屋タイプ：
                    {hotel.room_type}
                  </p>
                )}
                {hotel.isExpedia() && hotel.bed_types ? (
                  <p>
                    ベッドタイプ：
                    {hotel.bed_types.map(bedType => bedType.description).join('、')}
                  </p>
                ) : (
                  <p>ベッドタイプ：-</p>
                )}
                {!isPackage && (
                  <p>
                    ホテルの返金：
                    {hotel.refundable == null ? (
                      'お問合せください'
                    ) : hotel.refundable ? (
                      '可'
                    ) : (
                      <ReserveConfirmCancelStatus>不可</ReserveConfirmCancelStatus>
                    )}
                  </p>
                )}
              </TextDetail>
              {hotel.averagePriceDetailArray().map((d: any, i: number) => (
                <PriceDetail key={i}>
                  <p>{d.title}</p>
                  <p>{d.price}</p>
                </PriceDetail>
              ))}
            </>
          ) : (
            '-'
          )}
        </>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const PriceDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 100px;
`;

const ReserveConfirmCancelStatus = styled.span`
  font-weight: bold;
  color: #959595;
  padding: 6px 10px 10px 10px;
`;

const TextDetail = styled.div`
  font-size: 0.9em;
  padding-left: 0.9em;
  font-weight: normal;
`;

export default ReserveConfirmHotelTr;
