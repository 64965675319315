import { Fetcher } from '@this/src/util';
import React from 'react';
import { styled } from '@this/constants/themes';
import { css } from 'styled-components';
import Header from '@this/shared/header/header';
import Footer from '@this/shared/footer/footer';
import ContentBody from '@this/shared/atoms/content_body';
import { reportError } from '@this/lib/bugsnag';
import type UsersJson from '../../../../../domain/user/user_json';
import User from '../../../../../domain/user/user';

interface Props {}

interface State {
  user: User | null;
}

class Charges extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: null
    };
  }

  componentDidMount() {
    Fetcher.get<UsersJson>('/users.json').then(
      response => {
        const user = new User(response);
        this.setState({ user });
      },
      () => {
        this.setState({
          user: null
        });
      }
    );
  }

  render() {
    try {
      const { user } = this.state;
      return (
        <Wrap>
          <div className="charges__pc">
            <div>
              <Header user={user} />
              <Body>
                <InfoContent>
                  <Title>旅行業務取扱い料金表</Title>
                  <Small>
                    （この書面は旅行業法第12条の4に定める取引条件説明書面の一部となります。）
                    <br />
                    平素から、AI
                    Travelをご利用いただき、まことにありがとうございます。当社では、お客様のご希望によって旅行業務をお引き受けする場合、このご案内に記載された条件によってお引き受けいたします。あらかじめご了承くださいますようお願い申し上げます。また、このご案内に記載のない事項については当社旅行業約款によります。
                  </Small>
                  <SubTitle>国内旅行</SubTitle>
                  <table>
                    <tbody>
                      <tr>
                        <Th colSpan={2}>内容</Th>
                        <Th>料金</Th>
                        <Th>取り扱い単位</Th>
                        <Th>備考</Th>
                      </tr>
                      <tr>
                        <Td rowSpan={5}>取扱い料金</Td>
                        <Td>宿泊機関の手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td rowSpan={5}>
                          旅行契約成立後、お客様のお申し出により、旅行を中止される場合でも、取扱料金を収受いたします。
                        </Td>
                      </tr>
                      <tr>
                        <Td>運送機関等を手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                      </tr>
                      <tr>
                        <Td>航空券を手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                      </tr>
                      <tr>
                        <Td>会議室等の施設を手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                      </tr>
                      <tr>
                        <Td>宿泊機関と運送機関・航空券等を複合手配する場合</Td>
                        <Td>費用の20％以内（下限2,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                      </tr>
                      <tr>
                        <Td rowSpan={5}>変更手続料金</Td>
                        <Td>宿泊機関の手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>運送機関等を手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>航空券を手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>会議室等の施設を手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>宿泊機関と運送機関・航空券等を複合手配する場合</Td>
                        <Td>費用の20％以内（下限2,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td rowSpan={5}>取消手続料金</Td>
                        <Td>宿泊機関の手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>運送機関等を手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>航空券を手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>会議室等の施設を手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>宿泊機関と運送機関・航空券等を複合手配する場合</Td>
                        <Td>費用の20％以内（下限2,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>払戻手続料金</Td>
                        <Td>各種商品の払戻手続を手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>通信連絡料金</Td>
                        <Td>お客さまのご依頼により緊急に現地手配・取消・変更等のため通信連絡を行った場合</Td>
                        <Td>1,000円</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                    </tbody>
                  </table>
                  <SubTitle>海外旅行</SubTitle>
                  <table>
                    <tbody>
                      <tr>
                        <Th colSpan={2}>内容</Th>
                        <Th>料金</Th>
                        <Th>取り扱い単位</Th>
                        <Th>備考</Th>
                      </tr>
                      <tr>
                        <Td rowSpan={3}>取扱い料金</Td>
                        <Td>宿泊機関の手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td rowSpan={3}>
                          旅行契約成立後、お客様のお申し出により、旅行を中止される場合でも、取扱料金を収受いたします。
                        </Td>
                      </tr>
                      <tr>
                        <Td>運送機関等を手配する場合</Td>
                        <Td>費用の20％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                      </tr>
                      <tr>
                        <Td>航空券を手配する場合</Td>
                        <Td>費用の40％以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                      </tr>
                      <tr>
                        <Td rowSpan={3}>変更手続料金</Td>
                        <Td>宿泊期間の予約変更</Td>
                        <Td>費用の40%以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>運送機関等の予約変更</Td>
                        <Td>費用の40%以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>航空券の変更</Td>
                        <Td>費用の40%以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td rowSpan={3}>取消手続料金</Td>
                        <Td>宿泊期間の取消・払戻</Td>
                        <Td>費用の40%以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>運送機関等の取消・払戻</Td>
                        <Td>費用の40%以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>航空券の取消・払戻</Td>
                        <Td>費用の40%以内（下限1,000円）</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                      <tr>
                        <Td>通信連絡料金</Td>
                        <Td>お客さまのご依頼により緊急に現地手配・取消・変更等のため通信連絡を行った場合</Td>
                        <Td>1,000円</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td />
                      </tr>
                    </tbody>
                  </table>
                  <SubTitle>渡航手続代行料金</SubTitle>
                  <table>
                    <tbody>
                      <tr>
                        <Th colSpan={2}>内容</Th>
                        <Th>料金</Th>
                        <Th>取り扱い単位</Th>
                        <Th>備考</Th>
                      </tr>
                      <tr>
                        <Td rowSpan={2}>査証取扱い</Td>
                        <Td>査証（商用・業務査証の申請書作成と申請・受領代行）</Td>
                        <Td>66,000円以内</Td>
                        <Td>1名1件1カ国につき</Td>
                        <Td rowSpan={2}>
                          査証代実費や、査証取得のための手続きを委託する場合の査証取得手続代行業者の委託料は別途申し受けます。
                        </Td>
                      </tr>
                      <tr>
                        <Td>緊急査証手続き</Td>
                        <Td>66,000円以内</Td>
                        <Td>1名1件1カ国につき、上記通常の査証料金と別途で発生します</Td>
                      </tr>
                      <tr>
                        <Td>電子渡航認証</Td>
                        <Td>手続き可能なものはESTA/ETAS/eTAの3種類です。</Td>
                        <Td>66,000円以内</Td>
                        <Td>1名1件1カ国につき</Td>
                        <Td>取得のための手続きを委託する場合、取得手続代行業者の委託料は別途申し受けます。</Td>
                      </tr>
                      <tr>
                        <Td>出入国記録書</Td>
                        <Td>出入国カード／税関申告書などの記入</Td>
                        <Td>11,000円以内</Td>
                        <Td>1名1件1カ国につき</Td>
                        <Td>出入国カードが入手出来る場合に限ります。</Td>
                      </tr>
                      <tr>
                        <Td>その他</Td>
                        <Td>上記に含まれない手続き代行</Td>
                        <Td>66,000円以内</Td>
                        <Td>1名1件1手配につき</Td>
                        <Td>当社がお引き受け可能と判断した場合に限ります。</Td>
                      </tr>
                    </tbody>
                  </table>
                  <AddText>
                    ※備考
                    <p>上記の各料金については、消費税は含まれていません。</p>
                    <p>
                      上記料金は旅行を中止される場合でも払戻いたしません。取扱・変更手続料金は当該手配を取消される場合でも払戻いたしません。
                    </p>
                    <p>
                      上記料金には電話料、通信費、送料等実費は含まれません。通信実費を別途申し受ける場合があります。
                    </p>
                    <p>航空会社・ホテル等旅行サービス提供機関等に対して支払う取消料は別途申し受けます。</p>
                    <p>「費用」とは、運賃･宿泊料その他の名目で、運送・宿泊機関等に対して支払う費用をいいます。</p>
                    <p>
                      「1手配」とは、依頼の最小単位（片道交通機関、1宿泊施設の予約等）とします。手配日・利用日・利用期間・利用区間・提供機関等が異なる場合はそれぞれ「1手配」と数えます。
                    </p>
                    <p>変更・取消手続料金は、手配着手後の変更・取消より申し受けます。</p>
                    <p>変更・取消・払戻は場合によりお引受できないことがあります。</p>
                    <br />
                    <p>本規約は、日本標準時間2017年9月27日より有効とします。</p>
                    <p>制定：2017年9月27日</p>
                    <p>改定：2019年10月15日</p>
                    <p>改定：2022年1月15日</p>
                    <p>改定：2022年3月1日</p>
                    <p>改定：2022年11月25日</p>
                  </AddText>
                </InfoContent>
              </Body>
              <Footer signedIn={!!user} />
            </div>
          </div>
        </Wrap>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export const Wrap = styled.div`
  &__small {
    font-size: 10px;
    margin-bottom: 5px;
    margin-left: calc(150px + 10px);
  }

  .key-value-table__label {
    width: 150px;
  }
`;

export const Body = styled(ContentBody)`
  max-width: 1150px;
  padding: 20px;
  background: none;
`;

export const InfoContent = styled.div`
  flex-grow: 9999;
  border: 10px solid #eee;
  padding: 10px;
  position: relative;
  background: white;
`;

export const Title = styled.h1`
  font-size: 20px;
  border-bottom: 1px solid ${props => props.theme.grayBorderColor};
  padding-bottom: 10px;
`;

export const Small = styled.div`
  font-size: 11px;
  margin-top: 10px;
`;

export const SubTitle = styled.h2`
  margin-top: 20px;
`;

const Column = css`
  border: solid 1px #eee;
  padding: 2px 4px;
  font-size: 12px;
  line-height: 20px;
`;

const Th = styled.th`
  ${Column}
  background-color: #f7f7f7;
`;

const Td = styled.td`
  ${Column}

  &:last-child {
    min-width: 100px;
  }
`;

export const AddText = styled.div`
  font-size: 12px;
  color: ${props => props.theme.grayTextColor};
`;

export default Charges;
