import React from 'react';
import _ from 'lodash';

import { reportError } from '@this/lib/bugsnag';
import type Transit from '../../../../domain/transit/transit';
import type { Tab } from '../../../../domain/select_store';
import type SelectStore from '../../../../domain/select_store';

import SelectTransportBoxForeignTemplate from './transport_box_foreign.template';
import SelectTransportBoxDomesticTemplate from './transport_box_domestic.template';

export interface SelectTransportBoxProps {
  key?: string;
  handleClickTab?: (tab: Tab) => void;
  handleSelect?: (t: Transit, opts?: { changeable?: boolean; domesticAirPriceIndex?: number }) => void;
  transport: Transit;
  inList: boolean;
  selected: boolean;
  domesticAirPriceIndex: number;
  tab?: 'outword' | 'homeword' | 'packageOutword' | 'packageHomeword' | number;
  store: SelectStore;
  loading?: boolean;
  direction?: string;
  disabled?: boolean;

  // ArrangementRequestForm
  isRequestForm?: boolean;
}

export interface SelectTransportBoxState {
  loading: boolean;
}

class SelectTransportBox extends React.Component<SelectTransportBoxProps, SelectTransportBoxState> {
  constructor(props: SelectTransportBoxProps) {
    super(props);

    this.state = { loading: false };
  }

  handleSelectChangeable = () => {
    if (this.props.handleSelect) this.props.handleSelect(this.props.transport, { changeable: true });
  };

  handleSelectUnchangeable = () => {
    if (this.props.handleSelect) this.props.handleSelect(this.props.transport, { changeable: false });
  };

  handleDomesticAirPriceChange = (index: number) => {
    if (this.props.transport.prices == null) return;
    if (this.props.handleSelect) {
      const selectedPrice = this.props.transport.prices[index];
      this.props.handleSelect(this.props.transport, {
        changeable: selectedPrice.ticket_category === 'changeable',
        domesticAirPriceIndex: index
      });
    }
  };

  handleChange = async () => {
    if (this.props.inList) {
      if (this.props.handleSelect) {
        this.setState({ loading: true });
        await this.props.handleSelect(this.props.transport);
        this.setState({ loading: false });
      }
    } else if (this.props.tab !== undefined) {
      if (this.props.handleClickTab) this.props.handleClickTab(this.props.selected ? 'none' : this.props.tab);
    }
  };

  render() {
    try {
      return this.props.store.result.items.some(i => i.isDomestic) ? (
        <SelectTransportBoxDomesticTemplate
          {...this.props}
          {...this.state}
          handleDomesticAirPriceChange={this.handleDomesticAirPriceChange}
          handleChange={this.handleChange}
        />
      ) : (
        <SelectTransportBoxForeignTemplate
          {...this.props}
          {...this.state}
          handleDomesticAirPriceChange={this.handleDomesticAirPriceChange}
          handleChange={this.handleChange}
        />
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export default SelectTransportBox;
