import { Fetcher } from '@this/src/util';
/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import { styled } from '@this/constants/themes';
import { withTheme } from 'styled-components';
import { media } from '@this/components/shared/atoms/media';
import type Hotel from '@this/domain/hotel/hotel';
import type User from '@this/domain/user/user';
import type HotelList from '@this/domain/hotel/hotel_list';
import A from '@this/components/shared/atoms/a';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import Tooltip from '@material-ui/core/Tooltip';
import CachedIcon from '@material-ui/icons/Cached';
import { Text } from '@this/src/components/shared/ui/data_displays/typography';
import { getSpacing } from '@this/src/components/shared/ui/theme';
import HotelDetailModalPcTemplate from '@this/components/reserve_trip/select/hotel_detail_modal/hotel_detail_modal_pc_temlate';
import type { MarginTypeArgs } from '@this/domain/organization/margin_type2';
import MarginType from '@this/domain/organization/margin_type2';
import HotelModel from '@this/domain/hotel/hotel';
import _ from 'lodash';
import type { HotelJson } from '@this/domain/select_repository';
import type HotelStayPlan from '@this/domain/hotel/hotel_stay_plan';
import HotelDetailModalSpTemplate from '@this/components/reserve_trip/select/hotel_detail_modal/hotel_detail_modal_sp_template';
import { Button } from '@this/components/shared/ui/inputs/button';
import { Box } from '../shared/box_parts';

export const HotelBox = styled(Box)`
  flex-wrap: wrap;

  width: 100%;
  background: white;
  position: relative;
`;

const imageWidth = '100px';

const HotelImageAreaDiv = styled.div`
  min-width: ${imageWidth};
  min-height: 110px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  ${media.sp`
    width: 100%;
    height: 150px;
  `}
`;

export const HotelImageArea = ({ hotel }: { hotel: Hotel }) => (
  <HotelImageAreaDiv style={{ backgroundImage: `url(${hotel.image})` }} />
);

export const HotelContentArea = styled.div`
  flex-grow: 9999;
  display: flex;
  max-width: calc(100% - ${imageWidth}); /* 画像幅を引く */
  ${media.sp`
    max-width: 100%;
  `}
`;

export const Left = styled.div`
  padding: 5px 5px 5px 10px;
`;

export const HotelNameArea = styled.div`
  display: flex;
`;

export const HotelNameDiv = styled.div<{ inList?: boolean | null }>`
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
  max-width: ${props => (props.inList === false ? '186px' : '240px')};
  ${media.sp`
    width: 80px;
`}
`;

export const HotelName = ({ hotel, inList }: { hotel: Hotel; inList?: boolean }) => (
  <HotelNameArea>
    <HotelNameDiv data-wovn-ignore>{hotel.name}</HotelNameDiv>
  </HotelNameArea>
);

export const HotelBody = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Row = styled.div<{ margin?: string }>`
  display: flex;
  ${props => props.margin && `margin: ${props.margin};`}
`;

const LastRow = styled.div<{ margin?: string }>`
  flex: 1;
  display: flex;
  align-items: flex-end;
  ${props => props.margin && `margin: ${props.margin};`}
`;

const Cell = styled.div`
  float: left;
`;

const LabelCell = styled.div`
  float: left;
  width: max-content;
`;

const PlanName = styled.div<{ inList?: boolean | null; inCell?: boolean | null }>`
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${props =>
    props.inList === false
      ? props.inCell === true
        ? '165px'
        : '186px'
      : props.inCell === true
      ? '129px'
      : '240px'};
`;

const RoomType = styled.div<{ inList?: boolean | null }>`
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${props => (props.inList === false ? '186px' : '240px')};
`;

interface Props {
  destLocation?: { lat: number; lng: number } | null;
  me?: User | null;
  inList?: boolean | null;
  user: User;
  hotel: Hotel;
  theme: { themeClass: string };
  hotels: HotelList;
  hotelPriceLimit: number | null;
  searchQueryId: number | null;
  serviceId: number | undefined;
  handleChangeHotelStayPlan: (
    hotel: HotelJson,
    selectedPlan: HotelStayPlan,
    showFee: boolean,
    marginType: MarginType | undefined
  ) => void;
  submitButtonLabel?: string | null;
}

interface HotelResponse {
  hotel: HotelJson;
  show_fee: boolean;
  margin_type: MarginTypeArgs;
}

const HotelDetail = ({
  destLocation,
  me,
  inList,
  user,
  hotel,
  theme,
  hotels,
  hotelPriceLimit,
  searchQueryId,
  serviceId,
  handleChangeHotelStayPlan,
  submitButtonLabel
}: Props) => {
  const [selectedStayPlan, setSelectedStayPlan] = useState(
    hotel.stay_plans.find(plan => plan.id === hotel.selected_stay_plan_id)
  );

  useEffect(() => {
    setSelectedStayPlan(hotel.stay_plans.find(plan => plan.id === hotel.selected_stay_plan_id));
  }, [hotel.selected_stay_plan_id]);

  const [showDetailHotelPc, setShowDetailHotelPc] = useState<Hotel | null>(null);
  const [showHotelDetailModalPc, setShowHotelDetailModalPc] = useState<boolean>(false);
  const [showDetailHotelSp, setShowDetailHotelSp] = useState<Hotel | null>(null);
  const [showHotelDetailModalSp, setShowHotelDetailModalSp] = useState<boolean>(false);

  const showDetailHotelModalPc = (detailHotel: Hotel, hotels: HotelList) => {
    setShowHotelDetailModalPc(true);
    fetchAndShowDetailHotel(detailHotel, setShowDetailHotelPc, hotels);
  };

  const closeHotelDetailModalPc = () => {
    setShowHotelDetailModalPc(false);
    setShowDetailHotelPc(null);
  };

  const showDetailHotelModalSp = (detailHotel: Hotel, hotels: HotelList) => {
    setShowHotelDetailModalSp(true);
    fetchAndShowDetailHotel(detailHotel, setShowDetailHotelSp, hotels);
  };

  const closeHotelDetailModalSp = () => {
    setShowHotelDetailModalSp(false);
    setShowDetailHotelPc(null);
  };

  const fetchAndShowDetailHotel = (
    detailHotel: Hotel,
    setShowDetailHotel: (hotel: HotelModel) => void,
    hotels: HotelList
  ) => {
    Fetcher.get<HotelResponse>(`/hotels/${detailHotel.id}`).then(result => {
      const localHotel = hotels.find(result.hotel.id);
      let marginType: MarginType | undefined;
      if (result.margin_type) {
        marginType = new MarginType(result.margin_type);
      }
      // ホテルモデルの更新
      const uphotel = new HotelModel(
        _.merge(result.hotel, {
          plan_name: localHotel?.plan_name,
          selected_stay_plan_id: localHotel?.selected_stay_plan_id,
          showFee: result.show_fee,
          marginType,
          walkminute: detailHotel.walkminute
        })
      );
      setShowDetailHotel(uphotel);
    });
  };
  return (
    <Body>
      <Row>
        {hotel.breakfast && <Breakfast />}
        {typeof selectedStayPlan?.smoke === 'boolean' ? (
          <Smoke isSmoke={selectedStayPlan.smoke} />
        ) : typeof hotel.room_smoke === 'boolean' ? (
          <Smoke isSmoke={hotel.room_smoke} />
        ) : (
          ''
        )}
      </Row>
      <Row>
        <LabelCell className="select-hotel-box__detail-label">プラン</LabelCell>
        <Cell className="select-hotel-box__detail-separator">：</Cell>
        <Cell data-wovn-ignore className="select-hotel-box__detail-value">
          {hotel.planDisplayType() === 'tooltip' ? (
            <Tooltip title={hotel.plan_name ?? ''} placement="right">
              <PlanName inList={inList} inCell data-wovn-ignore>
                {hotel.plan_name}
              </PlanName>
            </Tooltip>
          ) : (
            hotel.plan_name || '設定なし'
          )}
        </Cell>
      </Row>
      <Row>
        {hotel.plan_name !== hotel.room_type && (
          <Tooltip title={hotel.room_type ? hotel.room_type : ''} placement="right">
            <RoomType inList={inList} data-wovn-ignore className="select-hotel-box__detail-value">
              {hotel.room_type}
            </RoomType>
          </Tooltip>
        )}
      </Row>
      <Row>
        <Cell className="select-hotel-box__detail-label">目的地まで</Cell>
        <Cell className="select-hotel-box__detail-separator">：</Cell>
        <Cell data-wovn-ignore className="select-hotel-box__detail-value">
          {hotel.walkminute ? `徒歩${hotel.walkminute}` : hotel.latitude && hotel.longitude ? '(計算中...)' : '-'}
        </Cell>
      </Row>
      <Row>
        <Cell className="select-hotel-box__detail-label">最寄駅まで</Cell>
        <Cell className="select-hotel-box__detail-separator">：</Cell>
        <Cell data-wovn-ignore className="select-hotel-box__detail-value">
          {hotel.station_distance ? `徒歩${Math.floor(hotel.station_distance / 80)}分` : '-'}
        </Cell>
      </Row>
      <Row>
        <Cell className="select-hotel-box__detail-label">キャンセル</Cell>
        <Cell className="select-hotel-box__detail-separator">：</Cell>
        <Cell className="select-hotel-box__detail-value">
          {hotel.package_type && theme.themeClass === 'mynavi' ? (
            <A
              href="https://btm.mynavi.jp/terms/pdf/cancellation_policy_rakutendp.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              詳細を確認
            </A>
          ) : hotel.package_type ? (
            `出発の21日前まで無料`
          ) : ['r', 'new_rakuten', 'agoda'].includes(hotel.type || '') && !hotel.sold_out ? (
            <>
              <HotelDetailModalPc>
                <A rel="noreferrer noopener" onClick={() => showDetailHotelModalPc(hotel, hotels)}>
                  詳細を確認
                </A>
              </HotelDetailModalPc>
              <HotelDetailModalSp>
                <A rel="noreferrer noopener" onClick={() => showDetailHotelModalSp(hotel, hotels)}>
                  詳細を確認
                </A>
              </HotelDetailModalSp>
            </>
          ) : theme.themeClass === 'tabikobo' && hotel.refund === '不可' ? (
            <WarningText>{hotel.refund}</WarningText>
          ) : (
            hotel.refund
          )}
        </Cell>
      </Row>
      {/*
      本当は新版APIのJRPackegeでもホテルの詳細リンクを表示したいが、表示手段が現状不明なためコメントアウトする。
      {(!(hotel.package_type || hotel.sold_out) || hotel.package_type === 'RP' || hotel.package_type === 'JR') && ( <Row>
          <Cell>
            <A href={hotel.detail_path || ''} target="_blank" rel="noreferrer noopener">
              詳細を見る
              {hotel.package_type === 'RP' || hotel.package_type === 'JR' ? '(外部サイトに移動します)' : null}
            </A>
          </Cell>
        </Row>
      )}
    */}
      {(!(hotel.package_type || hotel.sold_out || hotel.too_late) || hotel.package_type === 'RP') && (
        <LastRow margin="2px 0 0 0">
          <Cell>
            <HotelDetailModalPc>
              {hotel.package_type !== 'RP' && (
                <Button color="sub" size="small" onClick={() => showDetailHotelModalPc(hotel, hotels)}>
                  詳細を確認
                </Button>
              )}
              {hotel.package_type === 'RP' && (
                <A href={hotel.detail_path || ''} target="_blank" rel="noreferrer noopener">
                  詳細を確認
                  {hotel.package_type === 'RP' ? '(外部サイトに移動します)' : null}
                </A>
              )}
            </HotelDetailModalPc>
            <HotelDetailModalSp>
              {hotel.package_type !== 'RP' && (
                <Button color="sub" size="small" onClick={() => showDetailHotelModalSp(hotel, hotels)}>
                  詳細を確認
                </Button>
              )}
              {hotel.package_type === 'RP' && (
                <A href={hotel.detail_path || ''} target="_blank" rel="noreferrer noopener">
                  詳細を確認
                  {hotel.package_type === 'RP' ? '(外部サイトに移動します)' : null}
                </A>
              )}
            </HotelDetailModalSp>
          </Cell>
          <HotelDetailModalPcTemplate
            destLocation={destLocation}
            me={me}
            user={user}
            hotel={showDetailHotelPc}
            hotels={hotels}
            hotelPriceLimit={hotelPriceLimit}
            showModal={showHotelDetailModalPc}
            serviceId={serviceId}
            searchQueryId={searchQueryId}
            handleChangeHotelStayPlan={handleChangeHotelStayPlan}
            submitButtonLabel={submitButtonLabel}
            onClose={closeHotelDetailModalPc}
          />
          <HotelDetailModalSpTemplate
            destLocation={destLocation}
            me={me}
            user={user}
            hotel={showDetailHotelSp}
            hotels={hotels}
            hotelPriceLimit={hotelPriceLimit}
            showModal={showHotelDetailModalSp}
            serviceId={serviceId}
            searchQueryId={searchQueryId}
            handleChangeHotelStayPlan={handleChangeHotelStayPlan}
            submitButtonLabel={submitButtonLabel}
            onClose={closeHotelDetailModalSp}
          />
        </LastRow>
      )}
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 126px;
`;

export const Right = styled.div`
  flex-shrink: 0;
  margin: auto 10px auto auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Tag = styled.div`
  margin: 4px 4px 5px 0;
  height: 14px;
  width: 45px;
  background-color: #bbb;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: white;
    font-size: 9px;
    letter-spacing: 0;
  }
`;

export const Breakfast = () => (
  <Tag>
    <span>朝食付き</span>
  </Tag>
);

const Smoke: React.FC<{ isSmoke: boolean }> = ({ isSmoke }) => {
  return (
    <Tag>
      <span>{isSmoke ? '喫煙' : '禁煙'}</span>
    </Tag>
  );
};

const NoteDiv = styled(Text).attrs({ color: 'danger', level: 'caption' })`
  margin: 0 0 ${getSpacing(1)}px 10px;
`;

export const Note = ({ hotel, hotelPriceLimit }: { hotel: Hotel; hotelPriceLimit: number | null }) => {
  const limit = hotelPriceLimit || 0;
  const isOverLimit = () => {
    return limit > 0 && hotel.getAveragePrice()! > limit;
  };

  if (!isOverLimit()) {
    return null;
  }

  return (
    <NoteDiv>
      <span>規程金額</span>
      <span data-wovn-ignore>{` +${utils.formatPrice(hotel.getAveragePrice()! - limit)}`}</span>
    </NoteDiv>
  );
};

export const NoteForPrice = ({
  price,
  stayDays,
  hotelPriceLimit
}: {
  price: number;
  stayDays?: number | null;
  hotelPriceLimit: number | null;
}) => {
  const limit = (hotelPriceLimit || 0) * (stayDays || 1);
  const isOverLimit = () => {
    return limit > 0 && price > limit;
  };

  if (!isOverLimit()) {
    return null;
  }

  return (
    <NoteDiv>
      <span>規定金額</span>
      <span data-wovn-ignore>{` +${utils.formatPrice(price - limit)}`}</span>
    </NoteDiv>
  );
};

export const Rating = ({ rating }: { rating: number }) => <RatingDiv rating={rating} />;

const RatingDiv = styled.div<{ rating: number | undefined }>`
  position: relative;
  display: inline;
  letter-spacing: 0;
  &::before,
  &::after {
    content: '★★★★★';
  }
  &::before {
    color: #ccc;
    width: 5em;
  }
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    color: #ffa500;
    overflow: hidden;
    width: ${props => props.rating}em;
  }
`;

export const AnnounceOverLimit = ({ isOverLimit, loading }: { isOverLimit: boolean; loading: boolean }) => {
  if (!isOverLimit) return null;
  if (loading) return null;
  return (
    <AnnounceOverLimitDiv>
      <StyledAnnouncementIcon />
      <span>規定値オーバー</span>
    </AnnounceOverLimitDiv>
  );
};

const AnnounceOverLimitDiv = styled(Text).attrs({ color: 'danger', level: 'caption' })`
  display: flex;
  gap: 2px;
  align-items: center;
`;

const StyledAnnouncementIcon = styled(AnnouncementIcon)`
  font-size: 13px;
`;

export const FetchNewPriceText = ({ isOverLimit, loading }: { isOverLimit: boolean; loading: boolean }) => {
  if (isOverLimit) return null;
  if (loading) return null;
  return (
    <FetchNewPriceTextDiv>
      <StyledCachedIcon />
      <span>最新の料金を取得しました</span>
    </FetchNewPriceTextDiv>
  );
};

const FetchNewPriceTextDiv = styled.div`
  color: ${props => props.theme.successColor};
`;

const StyledCachedIcon = styled(CachedIcon)`
  font-size: 13px;
  vertical-align: bottom;
`;

const WarningText = styled.span`
  font-weight: bold;
  color: ${props => props.theme.redColor};
`;

const HotelDetailModalPc = styled.div`
  display: block;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const HotelDetailModalSp = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
`;
export default withTheme(HotelDetail);
