import { Fetcher, trackClick } from '@this/src/util';
import React from 'react';
import _ from 'lodash';

import type { PaymentGatewayType, PaymentMethodType } from '@this/domain/organization/organization2';
import { reportError } from '@this/lib/bugsnag';
import type { PaymentGatewayMember } from '../../../../../../domain/reserve_info';
import type ReserveInfo from '../../../../../../domain/reserve_info';

import PaymentSectionTemplate from './payment_section.template';

interface CardsResponse {
  paymentGatewayMembers: PaymentGatewayMember[];
}

export type Props = {
  validationErrors: { [key: string]: string | undefined };
  reserveInfo: ReserveInfo;
  handleReserveInfoChange: (
    method: string
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
};

export type State = {
  deleteCardStatus: 'none' | 'deleting' | 'success' | 'fail';
  cardCompanyImageNum: '' | '01' | '02' | '03' | '04' | '05' | '06';
  paymentMethodType: PaymentMethodType | undefined;
  paymentGatewayType: PaymentGatewayType | undefined;
};

class PaymentSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deleteCardStatus: 'none',
      cardCompanyImageNum: '',
      paymentMethodType: undefined,
      paymentGatewayType: undefined
    };
  }

  componentDidMount() {
    this.setState({
      paymentMethodType: this.props.reserveInfo.paymentMethodType,
      paymentGatewayType: this.props.reserveInfo.paymentGatewayType
    });
  }

  fetchCards() {
    Fetcher.get<CardsResponse>('/payment/cards').then(
      result => {
        const { paymentGatewayMembers } = result;
        // cardSeqは渡されない
        /*
          if (this.props.cardSeq !== 'billing' && this.props.cardSeq !== 'new' && cards && cards.length > 0) {
            this.props.reserveInfo.setCardSeq(cards[0].card_seq);
          }
          */
        this.props.reserveInfo.setPaymentGatewayMembers(paymentGatewayMembers);
      },
      e => {
        // updateCardsは渡されない
        /* this.props.updateCards([]); */
      }
    );
  }

  handleAutoNextFocus = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.id.indexOf('card_number') >= 0) {
      this.judgeIssuingCompany();
    }
    if (e.keyCode < 48 || e.keyCode > 57) {
      // 「0-9」以外のKeyが押下された場合は何もしない
      return;
    }
    if (target.value && target.value.length === Number($(e.target).attr('maxLength'))) {
      $(`#${$(e.target).data('nextelementid')}`).focus();
    }
  };

  judgeIssuingCompany() {
    let imageNum: '' | '01' | '02' | '03' | '04' | '05' | '06' = '';
    const { cardNum01 } = this.props.reserveInfo;
    const { cardNum02 } = this.props.reserveInfo;
    if (cardNum01.length !== 0 && (cardNum02.length === 0 || cardNum01.length === 4)) {
      const companyNumStr = cardNum01 + cardNum02.substring(0, 2);
      const companyNum = Number(companyNumStr);
      const companyNum1 = Number(companyNumStr[0]);
      const companyNum2 = Number(companyNumStr.substr(0, 2));
      const companyNum3 = Number(companyNumStr.substr(0, 3));
      const companyNum4 = Number(companyNumStr.substr(0, 4));
      const companyNum5 = Number(companyNumStr.substr(0, 5));
      if (companyNum1 === 4) {
        imageNum = '01';
      } else if (companyNum1 === 5) {
        imageNum = '02';
      } else if ([34, 37].indexOf(companyNum2) >= 0) {
        imageNum = '03';
      } else if (companyNum4 >= 3528 && companyNum4 <= 3589) {
        imageNum = '04';
      } else if (
        [36, 38, 39].indexOf(companyNum2) >= 0 ||
        companyNum4 === 3095 ||
        (companyNum3 >= 300 && companyNum3 <= 305)
      ) {
        imageNum = '05';
      } else if (
        companyNum5 === 60110 ||
        companyNum2 === 65 ||
        (companyNum5 >= 60112 && companyNum5 <= 60114) ||
        (companyNum * 1 >= 601174 && companyNum * 1 <= 601179) ||
        (companyNum * 1 >= 601186 && companyNum * 1 <= 601199) ||
        (companyNum3 >= 644 && companyNum3 <= 649)
      ) {
        imageNum = '06';
      }
    }
    if (this.state.cardCompanyImageNum !== imageNum) {
      this.setState({
        cardCompanyImageNum: imageNum
      });
    }
  }

  handleDeleteCard =
    (payment_gateway_type: PaymentGatewayType | undefined, payment_gateway_uid: string) =>
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      trackClick('Delete card');
      e.preventDefault();
      this.setState({
        deleteCardStatus: 'deleting'
      });
      Fetcher.delete(`/payment/cards/${payment_gateway_uid}.json`, { payment_gateway_type }).then(
        result => {
          this.fetchCards();
          this.setState({
            deleteCardStatus: 'success'
          });
        },
        error => {
          this.setState({
            deleteCardStatus: 'fail'
          });
        }
      );
    };

  handlePaymentTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const paymentMethodType = e.target.value as PaymentMethodType;
    this.setState({ paymentMethodType });
    this.props.reserveInfo.setPaymentMethodType(paymentMethodType);
    if (
      paymentMethodType === 'card' &&
      this.props.reserveInfo.paymentGatewayMembers &&
      this.props.reserveInfo.paymentGatewayMembers.length > 0
    ) {
      this.props.reserveInfo.setPaymentTransaction({ uid: this.props.reserveInfo.paymentGatewayMembers[0].uid });
    }
  };

  handlePaymentTransaction = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.reserveInfo.setPaymentTransaction({ uid: e.target.value });
  };

  render() {
    try {
      return (
        <PaymentSectionTemplate
          {...this.props}
          {...this.state}
          handleAutoNextFocus={this.handleAutoNextFocus}
          handleDeleteCard={this.handleDeleteCard}
          handlePaymentTypeChange={this.handlePaymentTypeChange}
          handlePaymentTransaction={this.handlePaymentTransaction}
        />
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export default PaymentSection;
