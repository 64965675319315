import { Fetcher } from '@this/src/util';
import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@this/constants/themes';
import FaqItemForAIT from '@this/components/reserve_trip/dashboard/help_area_for_ait/faq_item_for_ait.template';
import { Link } from '@this/shared/ui/navigations/link';
import type { HeaderWithCodeProps } from '@this/components/reserve_trip/dashboard/help_area_for_ait/help_area_for_ait';
import type { KnowledgeCategoryResponseArgs } from '@this/domain/knowledge_category';
import KnowledgeCategory, { convertKnowledgeCategoryResponseToArgs } from '@this/domain/knowledge_category';
import { Loading } from '@this/shared/ui/feedbacks/loading';

type Props = {
  header: HeaderWithCodeProps;
  items: { type?: string; href: string; text: string }[];
  showSubCategory: boolean;
};

interface AccordionItemProps {
  subTitleText: string;
  items: { href: string; text: string }[];
  showSubCategory: boolean;
}

interface KnowledgeCategoriesResponse {
  knowledge_categories: KnowledgeCategoryResponseArgs[];
}

const AccordionItem: React.FC<AccordionItemProps> = ({ subTitleText, items, showSubCategory }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [knowledgeCategories, setKnowledgeCategories] = useState<KnowledgeCategory[]>([]);

  const fetchKnowledgeCategories = useCallback(() => {
    setIsLoading(true);
    Fetcher.get<KnowledgeCategoriesResponse>('/knowledge_categories', { include_children: true })
      .then(res => {
        setKnowledgeCategories(
          res.knowledge_categories.map(raw => new KnowledgeCategory(convertKnowledgeCategoryResponseToArgs(raw)))
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    fetchKnowledgeCategories();
  }, [fetchKnowledgeCategories]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionContents>
      <SubTitle onClick={toggleAccordion}>
        <KnowledgeIcon />
        {subTitleText}
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </SubTitle>
      {isOpen && (
        <AccordionContentsItems>
          <>
            <FaqSubTitle>閲覧の多いご質問</FaqSubTitle>
            <ul
              style={{
                listStyleType: 'none',
                margin: 0,
                padding: 0,
                display: 'flex',
                flexWrap: 'wrap'
              }}
            >
              {items.map((item, i) => (
                <FaqItemForAIT key={item.href} href={item.href} text={item.text} />
              ))}
            </ul>
            <div
              style={{
                padding: '24px 0',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px'
              }}
            >
              <FaqSubTitle>カテゴリから探す</FaqSubTitle>
              {isLoading ? (
                <Loading />
              ) : (
                <Categories>
                  {knowledgeCategories.length > 0 &&
                    knowledgeCategories.map(category => (
                      <div key={category.id} style={{ width: '100%' }}>
                        <Link
                          href={`/knowledge_categories/${category.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            width: '100%'
                          }}
                        >
                          <div
                            style={{
                              display: 'inline-flex',
                              flexDirection: 'column',
                              gap: '12px',
                              padding: '24px',
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              border: '2px #ECECEC solid',
                              cursor: 'pointer',
                              color: '#3A3A3A',
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '24px',
                              wordWrap: 'break-word'
                            }}
                          >
                            <img
                              src={`/images/help/category_${category.id}.svg`}
                              alt={`category_${category.id}`}
                            />
                            {category.sfName}
                          </div>
                        </Link>
                        {showSubCategory && (
                          <SubCategories>
                            {category.children.length > 0 &&
                              category.children.map(
                                subCat =>
                                  subCat.knowledgesCount > 0 && (
                                    <li key={subCat.id} style={{ padding: '6px 16px 6px 0' }}>
                                      <SubCategoryLink
                                        href={`/knowledge_categories/${category.id}#${subCat.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {subCat.sfName} ({subCat.knowledgesCount})
                                      </SubCategoryLink>
                                    </li>
                                  )
                              )}
                          </SubCategories>
                        )}
                      </div>
                    ))}
                </Categories>
              )}
            </div>
          </>
        </AccordionContentsItems>
      )}
    </AccordionContents>
  );
};

const FaqBlockForAIT: React.FC<Props> = ({ header, items, showSubCategory }) => {
  return (
    <Wrapper>
      <Header>
        <MainTitle>{header.mainTitle}</MainTitle>
        <>
          {header.subTitles.map((subTitle, index) => (
            <AccordionItem
              key={index}
              subTitleText={subTitle.text}
              items={items}
              showSubCategory={showSubCategory}
            />
          ))}
        </>
      </Header>
    </Wrapper>
  );
};

const ChevronDownIcon: React.FC = () => (
  <img src="/images/help/chevron_down_icon.svg" alt="chevron down" style={{ marginLeft: 'auto' }} />
);

const ChevronUpIcon: React.FC = () => (
  <img src="/images/help/chevron_up_icon.svg" alt="chevron up" style={{ marginLeft: 'auto' }} />
);

const KnowledgeIcon: React.FC = () => (
  <img src="/images/help/knowledge_icon.svg" alt="knowledge" style={{ marginRight: '8px' }} />
);

const Wrapper = styled.div`
  flex-basis: 320px;
  flex-grow: 999;
`;

const Header = styled.div`
  margin-bottom: 40px;
`;

const MainTitle = styled.div`
  color: #3a3a3a;
  font-size: 23px;
  font-weight: 700;
  line-height: 28.75px;
`;

const SubTitle = styled.div`
  cursor: pointer;
  padding: 24px;
  color: white;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  background: #292c35;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AccordionContents = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
`;

const AccordionContentsItems = styled.div`
  width: 100%;
  padding: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
`;

const FaqSubTitle = styled.div`
  color: #3a3a3a;
  font-size: 17px;
  font-weight: 700;
  line-height: 21.25px;
  word-wrap: break-word;
`;

const Categories = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;

  @media screen and (max-width: 430px) {
    flex-direction: column;
  }
`;

const SubCategories = styled.ul`
  margin-top: 24px;
  padding: 0 0 0 16px;
`;

const SubCategoryLink = styled(Link)`
  color: #3a3a3a;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  word-wrap: break-word;
`;
export default FaqBlockForAIT;
