import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import ContentBody from '@this/components/shared/atoms/content_body';

import ContentHeader from '@this/components/shared/content_header/content_header';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import Trip from '@this/domain/trip/trip';
import TripReport from '@this/domain/trip_report/trip_report/index';
import TripReportForm, { Section, Submit } from './trip_report_form/trip_report_form';
import TripReportBodyTemplate from '../trip_report_body.template';
import OrganizationError from './organization_error';

type Props = RouteComponentProps<{ id: string }>;

interface State {
  user: any;
  trip: any;
  report: any;
  projects: any;
  organizationError: any;
  expenseTypes: any;
  loading: boolean;
  submitting: boolean;
  confirming: boolean;
  error: string | null;
}

interface TripReportResult {
  report: any;
  user: any;
  trip: any;
  projects: any;
  organization_error: any;
  expense_types: any;
  show_fee: boolean;
}

class TripReportsNew extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: null,
      trip: null,
      report: null,
      projects: null,
      organizationError: null,
      expenseTypes: null,
      loading: true,
      submitting: false,
      confirming: false,
      error: null
    };

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleBackFromConfirm = this.handleBackFromConfirm.bind(this);
  }

  componentDidMount() {
    utils
      .jsonPromise<TripReportResult>(`/trip_reports/${this.props.match.params.id}/application.json`)
      .then(
        result => {
          this.setState({
            report: new TripReport(utils.snakeToCamelObject(result.report)),
            user: result.user,
            trip: new Trip(_.merge(result.trip, { showFee: result.show_fee })),
            projects: result.projects,
            organizationError: result.organization_error,
            expenseTypes: result.expense_types,
            loading: false
          });
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            loading: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  handleConfirm() {
    this.setState({ confirming: true });
  }

  handleBackFromConfirm() {
    this.setState({ confirming: false });
  }

  handleSubmit(e: React.MouseEvent<HTMLInputElement>) {
    e.preventDefault();
    this.setState({ submitting: true });
    utils
      .jsonPromise(`/trip_reports/${this.props.match.params.id}/apply`, this.state.report.toCreateParams(), 'PUT')
      .then(
        _result => {
          location.href = '/trip_reports';
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            submitting: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  render() {
    try {
      const { history } = this.props;
      const {
        user,
        trip,
        report,
        loading,
        confirming,
        submitting,
        error,
        organizationError,
        expenseTypes,
        projects
      } = this.state;
      return (
        <Wrap>
          <BodyWrap>
            <Body>
              {confirming ? (
                <>
                  <ContentHeader title="出張報告の作成" backButton onBack={this.handleBackFromConfirm} />
                  <BodyIn>
                    <Section>
                      <p>
                        以下の内容で出張報告を作成します。
                        <br />
                        よろしいですか？
                      </p>
                    </Section>
                    <TripReportBodyTemplate user={user} report={report} projects={projects} />
                    {submitting ? (
                      <SimpleLoading />
                    ) : (
                      <>
                        {error && <Error>{error}</Error>}
                        <Submit type="submit" value="出張報告を作成" onClick={e => this.handleSubmit(e)} />
                      </>
                    )}
                  </BodyIn>
                </>
              ) : (
                <>
                  <ContentHeader title="出張報告の作成" backButton backLink="/trips" history={history} />
                  <BodyIn>
                    {loading ? (
                      <SimpleLoading />
                    ) : error ? (
                      <Error>{error}</Error>
                    ) : organizationError ? (
                      <OrganizationError user={user} />
                    ) : (
                      <TripReportForm
                        user={user}
                        trip={trip}
                        report={report}
                        expenseTypes={expenseTypes}
                        projects={projects}
                        onConfirm={this.handleConfirm}
                      />
                    )}
                  </BodyIn>
                </>
              )}
            </Body>
          </BodyWrap>
        </Wrap>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrap = styled.div`
  min-height: 100vh;
`;

const BodyWrap = styled.div``;

const Body = styled(ContentBody)`
  max-width: 1150px;
  min-height: 100vh;
  padding: 20px;
  background: ${props => props.theme.contentBgColor};

  ${media.sp`
    padding: 0;
    background: #fff;
  `};
`;

const BodyIn = styled.div`
  padding: 20px;

  select {
    font-size: 12px;
  }
`;

const Error = styled.div`
  ${props => props.theme.redColor}
`;

export default TripReportsNew;
