import React from 'react';
import _ from 'lodash';

import UPSERT_MEMBER_FORMATS from '@this/components/organization/csv_bulk_action/member_file_formats';
import type { BulkActionAttr } from '../types';
import { BulkActionAttrServiceType } from '../types';
import { OrganizationTable, OrganizationTd, OrganizationTh } from '../organization.style';

interface Props {
  status: any;
  expenses?: boolean;
  exicUsageMode?: string;
}
interface State {
  changeAttrs: BulkActionAttr[];
}
export default class BulkUpsertActionUsers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      changeAttrs: UPSERT_MEMBER_FORMATS.filter((f: BulkActionAttr) => {
        // サービスタイプが適合しているか確認
        let isEnableServiceType = f.serviceType === BulkActionAttrServiceType.ALL;
        if (!isEnableServiceType) {
          isEnableServiceType = this.props.expenses
            ? f.serviceType === BulkActionAttrServiceType.BIZTRA
            : f.serviceType === BulkActionAttrServiceType.AI_TRAVEL;
        }

        // exicUsageMode に応じて属性を除外
        const isExcludedByMode =
          (this.props.exicUsageMode === 'card_based' && ['EXIC会員ID', 'EXICパスワード'].includes(f.summary)) ||
          (this.props.exicUsageMode === 'user_based' && f.summary === 'EXICカード番号');

        // 無効な名前の属性を除外
        const isExcludedAttr = ['email', 'birthday', 'gender'].includes(f.name);

        // サービスタイプが有効であり、除外条件に該当しない場合のみ true
        return isEnableServiceType && !isExcludedByMode && !isExcludedAttr;
      })
    };
  }

  getStatusClass = (user: any): string => (user.isSuccess() ? 'green' : user.isFail() ? 'red' : 'gray');

  getChangeAttrText = (user: any, name: string): string => {
    const change = user.hasChange(name);
    if (change) {
      const beforeValTest = change.before == null ? '[未設定]' : this.convertAttrText(change.before, name);
      const afterValText = change.after == null ? '[削除]' : this.convertAttrText(change.after, name);
      // exic_cardがobject型で返す為、変更がない場合もchange=trueになってしまうため、チェックする
      if (name === 'exic_card' && beforeValTest === afterValText) {
        return '-';
      }
      return `${beforeValTest} → ${afterValText}`;
    }
    return '-';
  };

  convertAttrText = (attr: any, attrName: string): any => {
    switch (attrName) {
      case 'domestic_approval_required':
      case 'foreign_approval_required':
        return attr ? '必要' : '不要';
      case 'show_other_members':
        return attr ? '表示する' : '表示しない';
      case 'grade':
        return attr.name;
      case 'exic_card':
        return `ID: ${attr.id}（${attr.exic_number})`;
      default:
        return attr;
    }
  };

  render() {
    const { status } = this.props;
    const { changeAttrs } = this.state;
    return (
      <OrganizationTable>
        <thead>
          <tr>
            <OrganizationTh>aアドレス</OrganizationTh>
            <OrganizationTh>ステータス</OrganizationTh>
            {changeAttrs.map((attr, i) => (
              <OrganizationTh key={i}>{attr.summary}</OrganizationTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {status.actionUsers.map((user: any, i: number) => (
            <tr key={i}>
              <OrganizationTd className="e2e-email">{user.email}</OrganizationTd>
              <OrganizationTd className={`e2e-status ${this.getStatusClass(user)}`}>
                {user.statusMessage()}
              </OrganizationTd>
              {changeAttrs.map((attr, i) => (
                <OrganizationTd key={i} className={`e2e-${_.replace(attr.name, /_/g, '-')}`}>
                  {this.getChangeAttrText(user, attr.name)}
                </OrganizationTd>
              ))}
            </tr>
          ))}
        </tbody>
      </OrganizationTable>
    );
  }
}
