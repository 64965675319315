import React from 'react';
import _ from 'lodash';

import type { Tab } from '@this/domain/select_store';
// import Flight from '../../../domain/flight/flight';
import type FlightSliceCandidate from '@this/domain/flight/flight_slice_candidate';
import { reportError } from '@this/lib/bugsnag';
import SelectFlightBoxTemplate from './flight_box.template';

export interface SelectFlightBoxProps {
  // Outline/List 共通
  // flight: Flight;
  flight: FlightSliceCandidate | undefined;
  selected: boolean;
  inList: boolean;
  isDomestic: boolean;

  // Outline用
  handleClickTab?: (tab: Tab) => void;
  tab: number;
  flightIndex: number;

  // List用
  key?: number;
  handleSelect?: (f: FlightSliceCandidate) => void;

  // ArrangementRequestForm
  isRequestForm?: boolean;
}

class SelectFlightBox extends React.Component<SelectFlightBoxProps> {
  handleChange = () => {
    if (this.props.inList) {
      if (this.props.handleSelect && this.props.flight) this.props.handleSelect(this.props.flight);
    } else if (this.props.handleClickTab && this.props.tab !== undefined) {
      this.props.handleClickTab(this.props.selected ? 'none' : this.props.tab);
    }
  };

  render() {
    try {
      return <SelectFlightBoxTemplate {...this.props} handleChange={this.handleChange} />;
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export default SelectFlightBox;
