export type ArrangerArgs = {
  id?: number;
  name?: string;
  last_name?: string;
  first_name?: string;
  email?: string;
  deleted_at?: string | null;
};

class Arranger {
  id: number | undefined;

  name: string;

  last_name: string;

  first_name: string;

  email: string;

  deleted_at: string | null;

  constructor(args: ArrangerArgs = {}) {
    this.id = args.id;
    this.name = args.name || '';
    this.last_name = args.last_name || '';
    this.first_name = args.first_name || '';
    this.email = args.email || '';
    this.deleted_at = args.deleted_at || null;
  }

  setLastName(value: string) {
    this.last_name = value;
    return app.render();
  }

  setFirstName(value: string) {
    this.first_name = value;
    return app.render();
  }

  setEmail(value: string) {
    this.email = value;
    return app.render();
  }

  submitParams() {
    return {
      last_name: this.last_name,
      first_name: this.first_name,
      email: this.email
    };
  }

  static empty() {
    return new Arranger({ last_name: '', first_name: '', email: '' });
  }
}

export default Arranger;
