import _ from 'lodash';
import type { MarginArgs } from '@this/domain/margin';
import Margin from '@this/domain/margin';
import MarginType from '@this/domain/organization/margin_type2';
import ShareholderTicket from '@this/domain/shareholder_ticket';
import type { OrderItemOriginalPriceArgs } from '@this/domain/order_item_original_price';
import OrderItemOriginalPrice from '@this/domain/order_item_original_price';
import type { OrderItemPriceDetailArgs } from '@this/domain/order_item_price_detail';
import OrderItemPriceDetail from '@this/domain/order_item_price_detail';
import { formatPrice } from '@this/src/util/price';
import { camelToSnakeObject } from '@this/src/util';
import type React from 'react';

export type OrderItemPriceArgs = {
  id?: number;
  price?: number;
  peopleNum?: number;
  originalPrice?: number;
  orderItemOriginalPrices?: Array<OrderItemOriginalPriceArgs>;
  orderItemPriceDetails?: Array<OrderItemPriceDetailArgs>;
  refund?: number;
  margin?: MarginArgs;
  marginType?: object;
  sectionNum?: number;
  shareholderTickets?: Array<object>;
  shareholderTicketIds?: object;
  showFee?: boolean;
  bulkTicketIds?: object;
  bulkTicketFragmentIds?: object;
  taxTypeId?: number;
  selfLoss?: boolean;
  debtWaiver?: boolean;
  lossWorkflowUrl?: string;
  flightPriceChangeDetail?: string;
};

class OrderItemPrice {
  id: number | undefined;

  price: number;

  peopleNum: number;

  originalPrice: number;

  refund: number;

  margin: Margin;

  marginType: MarginType | undefined;

  sectionNum: number;

  shareholderTickets: Array<ShareholderTicket>;

  shareholderTicketIds: Array<number | string>;

  showFee: boolean;

  bulkTicketIds: Array<number | string>;

  bulkTicketFragmentIds: Array<number | string>;

  taxTypeId: number | undefined;

  selfLoss: boolean;

  debtWaiver: boolean;

  lossWorkflowUrl: string;

  originalPrices: Array<OrderItemOriginalPrice>;

  priceDetails: Array<OrderItemPriceDetail>;

  flightPriceChangeDetail: string;

  constructor(args: OrderItemPriceArgs = {}) {
    this.id = args.id;
    this.price = args.price || 0;
    this.peopleNum = args.peopleNum || 1;
    this.originalPrice = args.originalPrice || 0;
    this.refund = args.refund || 0;
    this.margin = new Margin(args.margin || {});
    this.marginType = args.marginType ? new MarginType(camelToSnakeObject(args.marginType)) : undefined;
    this.sectionNum = args.sectionNum || 1;
    this.shareholderTicketIds = _.toArray(args.shareholderTicketIds) || [];
    if (this.shareholderTicketIds.length < 1) {
      this.shareholderTicketIds.push('');
    }
    this.showFee = args.showFee || false;
    this.bulkTicketIds = _.toArray(args.bulkTicketIds);
    this.bulkTicketFragmentIds = _.toArray(args.bulkTicketFragmentIds);
    this.shareholderTickets = args.shareholderTickets
      ? _.map(args.shareholderTickets, t => new ShareholderTicket(t))
      : [];
    this.originalPrices = args.orderItemOriginalPrices
      ? _.map(args.orderItemOriginalPrices, raw => new OrderItemOriginalPrice(raw))
      : [new OrderItemOriginalPrice({ orderItemOriginalPriceType: 1, price: this.price })];
    this.taxTypeId = args.taxTypeId;
    this.selfLoss = args.selfLoss || false;
    this.debtWaiver = args.debtWaiver || false;
    this.lossWorkflowUrl = args.lossWorkflowUrl || '';
    this.priceDetails = args.orderItemPriceDetails
      ? _.map(args.orderItemPriceDetails, raw => new OrderItemPriceDetail(raw))
      : [];
    this.flightPriceChangeDetail = args.flightPriceChangeDetail || '';
  }

  setPrice(value: number) {
    this.price = value;
    return app.render();
  }

  setOriginalPrice(value: number) {
    this.originalPrice = value;
    return app.render();
  }

  setRefund(value: number) {
    this.refund = value;
    return app.render();
  }

  setPeopleNum(value: number) {
    this.peopleNum = value;
    this.setBulkTicketLength(this.peopleNum);
    this.setBulkTicketFragmentLength(this.peopleNum);
    return app.render();
  }

  setBulkTicketLength(count: number) {
    this.bulkTicketIds = _.times(count, i => {
      return this.bulkTicketIds[i] || '';
    });
  }

  setBulkTicketFragmentLength(count: number) {
    this.bulkTicketFragmentIds = _.times(count, i => {
      return this.bulkTicketFragmentIds[i] || '';
    });
  }

  setBulkTicket(index: number, value: number) {
    this.bulkTicketIds[index] = value;
    return app.render();
  }

  setBulkTicketFragment(index: number, value: number) {
    this.bulkTicketFragmentIds[index] = value;
    return app.render();
  }

  setTaxTypeId(value: number) {
    this.taxTypeId = value;
    return app.render();
  }

  setSelfLoss(value: boolean) {
    this.selfLoss = value;
    return app.render();
  }

  setDebtWaiver(value: boolean) {
    this.debtWaiver = value;
    return app.render();
  }

  setLossWorkflowUrl(value: string) {
    this.lossWorkflowUrl = value;
    return app.render();
  }

  setPaymentMethod(value: number) {
    this.originalPrices.forEach(e => e.setPaymentMethod(value));
    return app.render();
  }

  handleShareholderTicketAdd() {
    this.shareholderTicketIds.push('');
    return app.render();
  }

  handleShareholderTicketChange(index: number, value: string) {
    this.shareholderTicketIds[index] = value;
    return app.render();
  }

  handleShareholderTicketRemove(index: number) {
    this.shareholderTicketIds.splice(index, 1);
    return app.render();
  }

  totalPrice() {
    let p = this.price;
    if (this.showFee) {
      p += this.margin.amount;
    }
    return p;
  }

  totalPriceWithAll() {
    return this.price + this.margin.amount;
  }

  setOriginalTotalPrice() {
    this.originalPrice = this.originalPrices.reduce((acc, e) => acc + e.price, 0);
  }

  calcMarginAmount(e: React.MouseEvent) {
    e.preventDefault();
    const marginAmount = this.computeMarginAmount();
    this.margin.setAmount(marginAmount);
    return app.render();
  }

  computeMarginAmount() {
    if (this.marginType && this.marginType.constructor.name === 'MarginType') {
      return this.marginType.calcMarginAmount(this.price, this.peopleNum, this.sectionNum);
      // 本来はOrderItemのcurrentMarginTypeでnewすべきだが、
      // is not constructorのエラーが出てセットできないため、ここでセットし直している。
      // 参考:https://github.com/BEST10developers/travel.ai/pull/5026
    }
    if (this.marginType) {
      this.marginType = new MarginType(camelToSnakeObject(this.marginType));
      return this.marginType.calcMarginAmount(this.price, this.peopleNum, this.sectionNum);
    }
    return MarginType.calcDefaultMarginAmount(this.peopleNum, this.sectionNum);
  }

  isNeedReCalcMarginAmount() {
    const currentMarginAmount = this.computeMarginAmount();
    return this.margin.amount !== currentMarginAmount;
  }

  description() {
    let d = `\
<料金>
${formatPrice(this.totalPrice())}\
`;
    if (this.priceDetails.length > 1) {
      d += _.map(this.priceDetails, detail => `\n└ ${detail.name}：${formatPrice(detail.price)}`).join('');
    } else {
      d += `\n└ 商品代金：${formatPrice(this.price)}`;
    }
    if (this.showFee && this.margin.amount > 0) {
      d += `\n└ 手数料：${formatPrice(this.margin.amount)}`;
    }
    return d;
  }

  updateParams() {
    return {
      price: this.price,
      original_price: this.originalPrice,
      original_prices: _.map(this.originalPrices, p => p.updateParams()),
      price_details: _.map(this.priceDetails, detail => detail.updateParams()),
      refund: this.refund,
      margin: this.margin.updateParams(),
      shareholder_ticket_ids: this.shareholderTicketIds,
      bulk_ticket_ids: this.bulkTicketIds,
      bulk_ticket_fragment_ids: this.bulkTicketFragmentIds,
      prev_price_id: this.id,
      tax_type_id: this.taxTypeId,
      self_loss: this.selfLoss,
      debt_waiver: this.debtWaiver,
      loss_workflow_url: this.lossWorkflowUrl
    };
  }

  validationErrors() {
    return _.flatMap(this.originalPrices, p => p.validationErrors());
  }
}

export default OrderItemPrice;
