import React from 'react';
import _ from 'lodash';

import { reportError } from '@this/lib/bugsnag';
import type SearchResultItem from '../../../../domain/search_result_item';

import SelectOutlineItemTemplate from './outline_item.template';

import type { Tab } from '../../../../domain/select_store';
import type SelectStore from '../../../../domain/select_store';

export interface SelectOutlineItemProps {
  icon: number;
  item: SearchResultItem;
  handleClickTab: (tab: Tab) => void;
  handleSelectItem: () => void;
  selected: boolean;
  hotelPriceLimit: number | null;
  store: SelectStore;
  direction?: string;

  // ArrangementRequestForm
  isRequestForm: boolean;
  handleOpenShowSearchBox: () => void;

  isTripsForm?: boolean;
}

class SelectOutlineItem extends React.Component<SelectOutlineItemProps> {
  handleCssTransitionExited = () => {
    if (this.props.item.elementList) this.props.item.elementList.handleTransitionExited();
    this.props.handleSelectItem();
  };

  render() {
    const isNeedTexts: string[] = this.props.isTripsForm ? ['削除する', '元に戻す'] : ['予約しない', '予約する'];
    try {
      return (
        <SelectOutlineItemTemplate
          {...this.props}
          isNeedTexts={isNeedTexts}
          handleCssTransitionExited={this.handleCssTransitionExited}
        />
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export default SelectOutlineItem;
