import moment from 'moment-timezone';
import type ElementBaseInterface from '@this/domain/element_base_interface';
import { camelToSnakeObject } from '@this/src/util';

export type WifiElementJson = {
  id?: number;
  wifiProviderOptions: Record<string, string>;
  startedAt?: string;
  endedAt?: string;
  provider?: string;
  area?: string;
  remark?: string;
  reservationNumber?: string;
};

class WifiElement implements ElementBaseInterface {
  id: number | undefined;

  type: string;

  wifiProviderOptions: Record<string, string>;

  provider: string;

  area: string;

  remark: string;

  reservationNumber: string;

  startedAt: moment.Moment;

  endedAt: moment.Moment;

  constructor(args: WifiElementJson) {
    this.id = args.id;
    this.type = 'wifi';
    this.wifiProviderOptions = camelToSnakeObject(args.wifiProviderOptions || {});
    this.startedAt = moment(args.startedAt);
    this.endedAt = args.endedAt ? moment(args.endedAt) : moment(this.startedAt).add(1, 'hour');
    this.provider = Object.keys(this.wifiProviderOptions)[0] || '';
    this.area = args.area || '';
    this.remark = args.remark || '';
    this.reservationNumber = args.reservationNumber || '';
  }

  handleStartedAtChange(value: moment.Moment) {
    this.startedAt = value;
    return app.render();
  }

  handleEndedAtChange(value: moment.Moment) {
    this.endedAt = value;
    return app.render();
  }

  handleChangeProvider(value: string) {
    this.provider = value;
    return app.render();
  }

  handleChangeArea(value: string) {
    this.area = value;
    return app.render();
  }

  handleChangeRemark(value: string) {
    this.remark = value;
    return app.render();
  }

  startDate() {
    return this.startedAt;
  }

  endDate() {
    return this.endedAt;
  }

  description() {
    return `\
<WiFi>
■プロバイダ：${this.providerName()}
■ご利用日時：${this.startedAt.format('MM/DD HH:mm')}〜${this.endedAt.format('MM/DD HH:mm')}
■ご利用エリア：${this.area}
■備考
${this.remark}\
`;
  }

  title() {
    return 'WiFi';
  }

  summary() {
    return `[WiFi]${this.providerName()}`;
  }

  detail() {
    return '';
  }

  structuredDetail() {
    return [];
  }

  mappingDescription() {
    return this.title();
  }

  providerName() {
    return this.wifiProviderOptions[this.provider] || '';
  }

  validationErrors(orderItemStatus = 0) {
    const errors = [];
    const needStatusGuard = orderItemStatus === 7 || orderItemStatus === 13;
    if (this.area === '') {
      errors.push(this.wifiValidationError());
    }
    if (this.endedAt < this.startedAt) {
      errors.push(this.timeValidationError());
    }
    if (this.reservationNumber === '' && needStatusGuard) {
      errors.push(this.reservationNumberValidationError());
    }
    return errors;
  }

  handleReservationNumberChange(value: string) {
    this.reservationNumber = value;
    return app.render();
  }

  updateParams() {
    return {
      type: this.type,
      provider: this.provider,
      started_at: this.startedAt.format('YYYY-MM-DD HH:mm:ss'),
      ended_at: this.endedAt.format('YYYY-MM-DD HH:mm:ss'),
      area: this.area,
      remark: this.remark,
      reservation_number: this.reservationNumber
    };
  }

  wifiValidationError() {
    return 'wifiのエリアを入力してください。';
  }

  timeValidationError() {
    return '利用開始以降を指定してください。';
  }

  reservationNumberValidationError() {
    return '注文番号を入力してください。';
  }
}

export default WifiElement;
