import moment from 'moment-timezone';
import ExpenseList from '@this/domain/expense/expense_list';
import Expense from '@this/domain/expense/expense';
import { snakeToCamelObject } from '@this/src/util';
import _ from 'lodash';
import type Trip from '@this/domain/trip/trip';

export type TripReportStatus = 'before_application' | 'applying' | 'approved';

export type TripReportArgs = {
  id: number;
  trip_id: number;
  origin?: string;
  destination?: string;
  departure_date?: string;
  return_date?: string;
  expense_list?: object;
  expenses?: object[];
  result?: string;
  status: TripReportStatus;
  final_destination?: string;
  purpose?: string;
  reject_reason?: string;
  pay_date?: string;
  trip?: Trip;
};

class TripReport {
  id: number;

  tripId: number;

  origin: string | undefined;

  destination: string | undefined;

  departureDate: moment.Moment;

  returnDate: moment.Moment;

  expenseList: ExpenseList;

  result: string;

  status: TripReportStatus;

  finalDestination: string;

  purpose: string;

  selected: boolean;

  rejectReason: string;

  payDateRaw: string | undefined;

  payDate: moment.Moment;

  trip: Trip | undefined;

  constructor(args: TripReportArgs) {
    this.trip = args.trip;

    const camelArgs = snakeToCamelObject(_.omit(args, 'trip'));
    this.id = camelArgs.id;
    this.tripId = camelArgs.tripId;
    this.origin = camelArgs.origin;
    this.destination = camelArgs.destination;
    this.departureDate = camelArgs.departureDate ? moment(camelArgs.departureDate) : moment();
    this.returnDate = camelArgs.returnDate ? moment(camelArgs.returnDate) : moment();
    this.expenseList = camelArgs.expenseList
      ? camelArgs.expenseList
      : args.expenses
      ? new ExpenseList(_.map(args.expenses, raw => new Expense(snakeToCamelObject(raw))))
      : new ExpenseList();
    this.result = camelArgs.result || '';
    this.status = camelArgs.status;
    this.finalDestination = camelArgs.finalDestination || '';
    this.purpose = camelArgs.purpose || '';
    this.selected = true;
    this.rejectReason = camelArgs.rejectReason;
    this.payDateRaw = camelArgs.payDate;
    this.payDate = this.payDateRaw ? moment(this.payDateRaw) : moment();
  }

  setOrigin(value: string) {
    this.origin = value;
    return app.render();
  }

  setDestination(value: string) {
    this.destination = value;
    return app.render();
  }

  setDepartureDate(value: moment.Moment) {
    this.departureDate = value;
    return app.render();
  }

  setReturnDate(value: moment.Moment) {
    this.returnDate = value;
    return app.render();
  }

  setPayDate(value: string) {
    this.payDateRaw = value;
    this.payDate = moment(value);
    return app.render();
  }

  setResult(value: string) {
    this.result = value;
    return app.render();
  }

  setFinalDestination(value: string) {
    this.finalDestination = value;
    return app.render();
  }

  setPurpose(value: string) {
    this.purpose = value;
    return app.render();
  }

  setRejectReason(value: string) {
    this.rejectReason = value;
    return app.render();
  }

  toggleSelected() {
    this.selected = !this.selected;
    return app.render();
  }

  addExpense() {
    return this.expenseList.addExpense();
  }

  removeExpense(expense: Expense) {
    return this.expenseList.removeExpense(expense);
  }

  typeSummary() {
    return this.expenseList.typeSummary();
  }

  selectedExpenses() {
    return this.expenseList.selectedItems();
  }

  errors() {
    let errors = [];
    if (!this.origin || this.origin.length <= 0) {
      errors.push('出発地を入力してください');
    }
    if (!this.destination || this.destination.length <= 0) {
      errors.push('目的地を入力してください');
    }
    if (!this.purpose || this.purpose.length <= 0) {
      errors.push('出張の目的を入力してください');
    }
    errors = _.concat(errors, this.expenseList.errors());
    return errors;
  }

  errorsForReject() {
    const errors = [];
    if (!this.rejectReason || this.rejectReason.length <= 0) {
      errors.push('差し戻し理由を入力してください');
    }
    return errors;
  }

  isValidForReject() {
    return _.isEmpty(this.errorsForReject());
  }

  totalPrice() {
    return this.expenseList.totalPrice();
  }

  tripTotalPrice() {
    return this.trip?.totalPrice() || 0;
  }

  totalPriceWithTrip() {
    return this.totalPrice() + this.tripTotalPrice();
  }

  totalPaidPrice() {
    return this.expenseList.totalPaidPrice();
  }

  totalUnpaidPrice() {
    return this.expenseList.totalUnpaidPrice();
  }

  statusBeforeApplication() {
    return this.status === 'before_application';
  }

  statusApplying() {
    return this.status === 'applying';
  }

  statusApproved() {
    return this.status === 'approved';
  }

  statusDescription() {
    const desc = { before_application: '申請前', applying: '申請中', approved: '承認済み' };
    return desc[this.status];
  }

  statusClass() {
    const classes = { before_application: 'gray', applying: 'yellow', approved: 'green' };
    return classes[this.status];
  }

  representativeName() {
    return this.trip ? this.trip.user.name : '';
  }

  payDateDescription() {
    if (this.payDateRaw) {
      return this.payDate.format('YYYY/MM/DD');
    }
    return '';
  }

  adminAvailableFor(action: string) {
    const availableActions = {
      before_application: [],
      applying: ['edit', 'approve', 'reject'],
      approved: []
    };
    return _.includes(availableActions[this.status], action);
  }

  toCreateParams() {
    return {
      trip_id: this.tripId,
      origin: this.origin,
      destination: this.destination,
      departure_date: this.departureDate.format('YYYY-MM-DD'),
      return_date: this.returnDate.format('YYYY-MM-DD'),
      expenses_attributes: this.expenseList.toCreateParams(),
      result: this.result,
      final_destination: this.finalDestination,
      purpose: this.purpose
    };
  }

  toApproveParams() {
    return { pay_date: this.payDate.format('YYYY-MM-DD') };
  }

  toCreateExpenseApplicationParams() {
    return {
      id: this.id,
      expenses: this.expenseList.toCreateExpenseApplicationParams()
    };
  }
}

export default TripReport;
