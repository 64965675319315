import { observable } from 'mobx';
import type { ShareholderTicketArgs } from '@this/domain/shareholder_ticket/shareholder_ticket';
import ShareholderTicket from '@this/domain/shareholder_ticket';
import type { Moment } from 'moment';
import moment from 'moment/moment';

export interface ShareholderTicketHistoryArgs {
  id?: number;
  trip_id?: number;
  order_item_id?: number;
  trace_id?: number;
  shareholder_ticket_id: number;
  shareholder_ticket_number: string;
  used_at?: Moment;
  shareholder_ticket?: ShareholderTicketArgs;
}

class ShareholderTicketHistory {
  @observable id?: number;

  @observable tripId?: number;

  @observable orderItemId?: number;

  @observable traceId?: number;

  @observable shareholderTicketId: number;

  @observable shareholderTicketNumber: string;

  @observable usedAt?: Moment;

  @observable shareholderTicket: ShareholderTicket;

  constructor(args: ShareholderTicketHistoryArgs) {
    this.id = args.id;
    this.tripId = args.trip_id;
    this.orderItemId = args.order_item_id;
    this.traceId = args.trace_id;
    this.shareholderTicketId = args.shareholder_ticket_id;
    this.shareholderTicketNumber = args.shareholder_ticket_number;
    this.usedAt = args.used_at ? moment(args.used_at) : undefined;
    this.shareholderTicket = new ShareholderTicket(args.shareholder_ticket);
  }
}

export default ShareholderTicketHistory;
