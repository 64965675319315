import { Fetcher } from '@this/src/util';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import {
  OrganizationTable,
  OrganizationTh,
  OrganizationTd,
  OrganizationTitle
} from '@this/components/organization/organization.style';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { SmartHrImportDataCrewResponseArgs } from '@this/domain/smart_hr_import_data_crew';
import SmartHrImportDataCrew, {
  convertSmartHrImportDataCrewResponseToArgs
} from '@this/domain/smart_hr_import_data_crew';

import Notification from '@this/src/notification';
import { TableBody, TableHead, TableRow } from '@this/shared/ui/data_displays/table';
import type SmartHrImportDataChangeAttr from '@this/domain/smart_hr_import_data_change_attr';
import { Link } from '@this/shared/ui/navigations/link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

type Props = any;

type Response = {
  smart_hr_import_data_crews: SmartHrImportDataCrewResponseArgs[];
};

const SmartHrImportDataCrews: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [smartHrImportDataCrews, setSmartHrImportDataCrews] = useState<SmartHrImportDataCrew[]>([]);
  const { id } = useParams<{ id: string }>();

  const fetchSmartHrImportDataCrew = async (importActionId: number) => {
    setLoading(true);
    setError('');
    await Fetcher.get<Response>(`/organization/smart_hr/import_actions/${importActionId}.json`)
      .then(result => {
        setSmartHrImportDataCrews(
          result.smart_hr_import_data_crews.map(
            r => new SmartHrImportDataCrew(convertSmartHrImportDataCrewResponseToArgs(r))
          )
        );
      })
      .catch(e => {
        const errorMessage =
          e.status === 400 && e.response.data?.errors?.message
            ? e.response.data.errors.message
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        Notification.error(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSmartHrImportDataCrew(Number(id));
  }, [id]);

  const getStatusClass = (data: SmartHrImportDataCrew): string =>
    data.isSuccess() ? 'green' : data.isFail() ? 'red' : 'gray';
  const convertChangeAttrText = (
    attr: SmartHrImportDataChangeAttr | null | undefined,
    data: SmartHrImportDataCrew
  ): string => {
    if (!data.isSuccess() || attr === null || attr === undefined) {
      return '-';
    }

    if (attr?.changed) {
      const beforeValTest = attr?.before === null ? '[未設定]' : attr?.before;
      const afterValText = attr?.after === null ? '[未設定]' : attr?.after;
      return `${beforeValTest} → ${afterValText}`;
    }
    return '-';
  };

  return (
    <>
      <OrganizationTitle>SmartHR連携 実行履歴詳細</OrganizationTitle>
      <BackLinkSection>
        <Link href="/organization/member/smart_hr/import_actions">
          <ArrowBackIcon />
          実行履歴一覧へ戻る
        </Link>
      </BackLinkSection>

      {loading ? (
        <SimpleLoading />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Wrap>
          <OrganizationTable>
            <TableHead>
              <TableRow>
                <OrganizationTh>メールアドレス</OrganizationTh>
                <OrganizationTh>登録種別</OrganizationTh>
                <OrganizationTh>ステータス</OrganizationTh>
                <OrganizationTHWidth>エラー</OrganizationTHWidth>
                <OrganizationTh>姓</OrganizationTh>
                <OrganizationTh>名</OrganizationTh>
                <OrganizationTh>カナ姓</OrganizationTh>
                <OrganizationTh>カナ名</OrganizationTh>
                <OrganizationTh>性別</OrganizationTh>
                <OrganizationTh>生年月日</OrganizationTh>
                <OrganizationTh>電話番号</OrganizationTh>
                <OrganizationTh>国籍</OrganizationTh>
              </TableRow>
            </TableHead>
            <TableBody>
              {smartHrImportDataCrews.map(data => (
                <TableRow key={data.id}>
                  <OrganizationTd>{data.changeAttr?.email?.after || data.smartHrEmail}</OrganizationTd>
                  <OrganizationTd>{data.importTypeToText()}</OrganizationTd>
                  <OrganizationTd className={`organization__td ${getStatusClass(data)}`}>
                    {data.statusToText()}
                  </OrganizationTd>
                  <OrganizationTd>
                    {data.errorMessage.map((message: string, index: number) => (
                      <div key={index}>{message}</div>
                    ))}
                    {data.errorMessageText()}
                  </OrganizationTd>
                  <OrganizationTd>{convertChangeAttrText(data.changeAttr?.lastName, data)}</OrganizationTd>
                  <OrganizationTd>{convertChangeAttrText(data.changeAttr?.firstName, data)}</OrganizationTd>
                  <OrganizationTd>{convertChangeAttrText(data.changeAttr?.lastNameKana, data)}</OrganizationTd>
                  <OrganizationTd>{convertChangeAttrText(data.changeAttr?.firstNameKana, data)}</OrganizationTd>
                  <OrganizationTd>{convertChangeAttrText(data.changeAttr?.gender, data)}</OrganizationTd>
                  <OrganizationTd>{convertChangeAttrText(data.changeAttr?.birthday, data)}</OrganizationTd>
                  <OrganizationTd>{convertChangeAttrText(data.changeAttr?.tel, data)}</OrganizationTd>
                  <OrganizationTd>{convertChangeAttrText(data.changeAttr?.nationalityId, data)}</OrganizationTd>
                </TableRow>
              ))}
            </TableBody>
          </OrganizationTable>
        </Wrap>
      )}
    </>
  );
};

const Wrap = styled.div`
  padding: 10px;
`;

const BackLinkSection = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  a {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const OrganizationTHWidth = styled(OrganizationTh)`
  width: 200px;
  max-width: 300px;
`;

export default SmartHrImportDataCrews;
