Traveler = require('./traveler')

class TravelerList
  constructor: (rawTravelers) ->
    @list = _.map rawTravelers, (t) ->
      rawUser = t.user || {}
      arg = _.merge(rawUser, t)
      arg.id ||= t.user_id
      arg.tel ||= t.flight_tel
      new Traveler(arg)

  @fromCount: (count) ->
    new @(_.times(count, -> {}))

  @fromTravelerInformations: (args) ->
    informations = args['informations'] # TravelerList
    user = args['user'] # User
    members = args['members'] # User
    peoplenum = args['peoplenum']

    # 出張人数分のtravelersを作成
    travelers = @fromCount(peoplenum)

    # travelersに、travelerInformationsをアサインする
    # self or memberの場合、travelrを作成してアサイン
    # companionの場合、そのままアサイン
    for info, i in informations.list
      if travelers.list[i]
        if info.user_id
          if parseInt(info.user_id) == parseInt(user.id)
            Object.assign user, info: info
            traveler = new Traveler(user)
            traveler.type = 'self'
            travelers.setTravelerAtIndex(i, traveler)
          else
            member = _.find members, (m) -> parseInt(m.id) == parseInt(info.user_id)
            if member
              Object.assign member, info: info
              traveler = new Traveler(member)
              traveler.type = 'member'
              travelers.setTravelerAtIndex(i, traveler)
            else
              Object.assign info.user, info: info
              traveler = new Traveler(info.user)
              traveler.type = 'others'
              travelers.setTravelerAtIndex(i, traveler)
        else
          info.type = 'companion'
          info.travelerInformationId = info.id
          info.id = '' # user_idとして扱われるため
          info.birthday = info.flight_birthday
          travelers.setTravelerAtIndex(i, info)

    travelers

  @fromQuery: (args) ->
    queries = args['travelerQueries']
    user = args['user'] # User
    Object.assign user, search_query_id: queries[0].search_query_id
    members = args['members'] # User
    peoplenum = args['peoplenum']

    travelers = @fromCount(peoplenum)

    for query, i in queries
      if travelers.list[i]
        if query.user_id
          if parseInt(query.user_id) == parseInt(user.id)
            Object.assign user, info: query
            traveler = new Traveler(user)
            traveler.type = 'self'
            travelers.setTravelerAtIndex(i, traveler)
          else
            member = _.find members, (m) -> parseInt(m.id) == parseInt(query.user_id)
            if member
              Object.assign member, { search_query_id: queries[0].search_query_id, info: query }
              traveler = new Traveler(member)
              traveler.type = 'member'
              travelers.setTravelerAtIndex(i, traveler)
        else
          traveler = new Traveler(query)
          traveler.travelerInformationId = query.id
          traveler.id = '' # traveler_informationのidがuser_idとして扱われるため
          traveler.type = 'companion'
          travelers.setTravelerAtIndex(i, traveler)

    travelers

  @initialTravelers: (args) ->
    user = args['user']
    peoplenum = args['peoplenum']

    travelers = @fromCount(peoplenum)
    travelers.setTravelerAtIndex(0, new Traveler(user))
    if peoplenum > 1
      _.forEach(_.range(1, peoplenum), (i) ->
        if user.show_other_members
          travelers.setTravelerTypeAtIndex(i, 'member')
        else
          travelers.setTravelerTypeAtIndex(i, 'companion')
      )
    travelers

  @fromTravelers: (args) ->
    travelers = args['travelers']
    peoplenum = args['peoplenum']
    user = args['user']
    if travelers != null
      travelers = travelers.map (traveler) ->
        if traveler.user_id != null
          traveler.name = traveler.user.name
          traveler.email = traveler.user.email
          if traveler.user_id == user.id
            traveler.type = 'self'
          else
            traveler.type = 'member'
        else
          traveler.type = 'companion'
          traveler.name = traveler.last_name_roman + ' ' + traveler.first_name_roman
        new Traveler(traveler)
    travelerlist = @fromCount(peoplenum)
    travelerlist.list = travelers
    travelerlist

  getFirstExistAccountTraveler: () ->
    _.find @list, (l) -> l.department.id

  getTravelersParam: (reservingTrip = {}) ->
    _.map @list, (t) -> t.getTravelerParams(reservingTrip)

  getQueryString: ->
    res = ''
    @list.forEach((traveler, i) ->
      res += traveler.getQueryString("travelers[#{i}]")
    )
    res

  getTravelerNames: ->
    _.map @list, (t) -> t.getTravelerName()

  getTravelerShinkansenTypes: ->
    if _.filter(@list, (t) -> t.isTravelerShinkansenType()).length > 0
      return _.map @list, (t) -> t.getTravelerShinkansenType()

    false

  setTravelerTypeAtIndex: (i, type) ->
    @list[i] = new Traveler({ type: type })
    @list[i].setInitialShinkansenTypes()
    app.render()

  setTravelerAtIndex: (i, traveler) ->
    @list[i] = traveler
    @list[i].setInitialShinkansenTypes()
    app.render()

  addTraveler: (raw = {}) ->
    @list.push(new Traveler(raw))
    app.render()

  removeTravelerAtIndex: (i) ->
    @list.splice(i, 1)
    app.render()

  removeLastTraveler: ->
    @list.pop()
    app.render()

  handleTravelerInfoChange: (i, method) ->
    (e) => @list[i][method](e.target.value)

  handleTravelerBirthDayChange: (i, method) ->
    (birthday) => @list[i][method](birthday.format('YYYY-MM-DD'))

  validationPromise: ->
    errors = {}
    @list.forEach (t, i) -> t.validate(errors, false)
    _.omitBy errors, _.isNil

module.exports = TravelerList
