import type { ExicCard } from '@this/domain/exic_card';
import React, { useState } from 'react';

import { Input } from '@this/shared/ui/inputs/input';
import { FormControl, InputLabel } from '@this/shared/ui/inputs/form_control';
import { ModalFooter, ModalBody, Modal, ModalHeader } from '@this/components/shared/ui/feedbacks/modal';
import { Button } from '@this/shared/ui/inputs/button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { styled } from '@this/constants/themes';

interface Props {
  exicCard: ExicCard | null | undefined;
  open: boolean;
  onAbort: () => void;
  onSubmit: (exicCard: ExicCard) => void;
}

const ExicCardForm: React.FC<Props> = ({ open, ...props }) => {
  return (
    <Modal open={open} onClose={props.onAbort}>
      <ModalContent {...props} />
    </Modal>
  );
};

const ModalContent = ({ exicCard, onSubmit, onAbort }: Omit<Props, 'open'>) => {
  const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const validateExicNumber = () => {
    return exicCard?.exicNumber.match(/^\d{10}$/) ? '' : 'EXIC会員IDは10桁の半角数字で入力してください。';
  };

  const validatePassword = () => {
    return exicCard?.password.match(/^[\w!@#$%^&*]{4,8}$/)
      ? ''
      : 'EXICパスワードは4〜8桁の半角文字で入力してください。';
  };

  const handleSubmit = () => {
    const errors = [validateExicNumber(), validatePassword()].filter(Boolean);
    setValidationErrors(errors);
    if (errors.length === 0 && exicCard) {
      onSubmit(exicCard);
    }
  };

  return (
    <>
      <ModalHeader>{exicCard?.id !== 0 ? 'EXICを編集' : 'EXICを作成'}</ModalHeader>
      <ModalBody>
        <FormControl fullWidth required>
          <InputLabel htmlFor="exic_number">EXIC会員ID</InputLabel>
          <Input
            id="exic_number"
            type="text"
            placeholder="半角数字10桁"
            value={exicCard?.exicNumber || ''}
            onChange={e => exicCard?.setExicNumber(e.target.value)}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth required>
          <InputLabel htmlFor="password">パスワード</InputLabel>
          <InputAreaFlex>
            <Input
              id="password"
              placeholder="半角数字記号4-8桁"
              type={passwordType}
              value={exicCard?.password || ''}
              onChange={e => exicCard?.setEPassword(e.target.value)}
              fullWidth
            />
            <div
              onClick={() => setPasswordType(prevType => (prevType === 'password' ? 'text' : 'password'))}
              style={{ cursor: 'pointer' }}
            >
              {passwordType === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </div>
          </InputAreaFlex>
        </FormControl>
        {validationErrors.length > 0 && (
          <ValidationError>
            {validationErrors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ValidationError>
        )}
      </ModalBody>

      <ModalFooter>
        <Button color="sub" onClick={onAbort}>
          キャンセル
        </Button>
        <Button onClick={handleSubmit}>{exicCard?.id !== 0 ? '更新' : '作成'}</Button>
      </ModalFooter>
    </>
  );
};

const InputAreaFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ValidationError = styled.div`
  color: ${props => props.theme.redColor};
  margin: 6px 10px;
`;

export default ExicCardForm;
