import { Fetcher } from '@this/src/util';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import Header from '@this/shared/header/header';
import Footer from '@this/shared/footer/footer';
import type UserJson from '@this/domain/user/user_json';
import User from '@this/domain/user/user';
import { reportError } from '@this/lib/bugsnag';
import InquiryForm from './inquiry_form/inquiry_form';
import InquiryThanks from './inquiry_form/inquiry_thanks';

interface Props {
  serviceId?: number;
}

interface State {
  user: User | null;
}

class Inquiries extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: null
    };
  }

  componentDidMount() {
    Fetcher.get<UserJson>('/users.json').then(
      response => {
        const user = new User(response);
        this.setState({ user });
      },
      () => {
        this.setState({
          user: null
        });
      }
    );
  }

  render() {
    try {
      const { serviceId } = this.props;
      const { user } = this.state;
      return (
        <div className="inquiries">
          <PcOnly>
            <div className="inquiries-pc">
              <Header user={user} />
              <InquiriesContentWrapper>
                <Switch>
                  <Route exact path="/inquiries/new" render={() => <InquiryForm user={user} />} />
                  <Route exact path="/inquiries/thanks" component={InquiryThanks} />
                </Switch>
              </InquiriesContentWrapper>
              <Footer signedIn={!!user} serviceId={serviceId} />
            </div>
          </PcOnly>
        </div>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const PcOnly = styled.div`
  min-height: 100vh;

  > :first-child {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
  }

  header {
    background: #292929;
    border-bottom: 1px solid #af985e;
  }

  .header-in {
    max-width: 100%;
    background: none;
    border-bottom: none;
    padding: 0 20px;
  }

  .logo {
    padding-left: 0;
  }

  .trips-button {
    margin-right: 0;
  }

  .in-advance-button {
    margin-right: 10px;
  }

  .content-wrapper {
    flex-grow: 9999;
    display: flex;
    flex-direction: column;
    min-height: 0%;
    width: 100%;
    background: #eee;
  }

  .content-body {
    max-width: 1030px;
    padding: 20px;
    background: none;
  }

  .info-content {
    position: relative;
    background: white;

    &__title {
      &__link {
        position: absolute;
        top: 10px;
        right: 10px;
        margin: 0;
      }
    }
  }
`;

const InquiriesContentWrapper = styled.div`
  background-color: ${props => props.theme.grayBgColor};
  padding: 20px;
  flex-grow: 9999;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
`;

export default Inquiries;
