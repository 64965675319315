import React from 'react';
import _ from 'lodash';

import { reportError } from '@this/lib/bugsnag';
import type OrganizationBase from '../../../../../../domain/organization_base/organization_base';

import OrganizationBasesList from './organization_bases_list/organization_bases_list';

type Props = {
  organizationBases: OrganizationBase[];
  onSelect: (OrganizationBases: OrganizationBase) => void;
  isRequired: boolean;
};

type State = {
  showOrganizationBasesList: boolean;

  selected?: OrganizationBase;
};

interface HTMLElementEvent<T extends EventTarget> extends Event {
  target: T;
}

class SelectOrganizationBases extends React.Component<Props, State> {
  private selectAreaRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);

    this.state = { showOrganizationBasesList: false };
  }

  componentDidMount() {
    if (this.props.organizationBases.length > 0) {
      const selected = this.props.organizationBases[0];
      if (selected) {
        this.setState({ selected }, () => this.onSelect(selected));
      }
    }

    document.addEventListener('click', {
      handleEvent: (e: HTMLElementEvent<HTMLElement>) => {
        this.handleClickEvent(e);
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', {
      handleEvent: (e: HTMLElementEvent<HTMLElement>) => {
        this.handleClickEvent(e);
      }
    });
  }

  handleClickEvent(e: HTMLElementEvent<HTMLElement>) {
    // ref内にクリックされたeventのDOMが含まれているかを確認する
    if (this.selectAreaRef && this.selectAreaRef.current && !this.selectAreaRef.current.contains(e.target)) {
      this.setState({ showOrganizationBasesList: false });
    }
  }

  handleOrganizationBaseSelect = (organizationBase: OrganizationBase) => {
    this.setState({
      showOrganizationBasesList: false,
      selected: organizationBase
    });
    return this.onSelect(organizationBase);
  };

  toggleOrganizationBasesList = (e: React.MouseEvent) => {
    e.preventDefault();
    return this.setState({ showOrganizationBasesList: !this.state.showOrganizationBasesList });
  };

  private onSelect(department: OrganizationBase) {
    return this.props.onSelect(department);
  }

  render() {
    const { isRequired } = this.props;
    try {
      return (
        <div className="select-travelers" ref={this.selectAreaRef}>
          <div className="select-travelers__wrapper">
            <div className="select-travelers__selected-list__item" onClick={this.toggleOrganizationBasesList}>
              {this.state.selected ? (
                <div className="members-list__item__info">
                  <p>{this.state.selected.description()}</p>
                </div>
              ) : (
                <p className="select-travelers__selected-list__message">
                  {isRequired ? '(必須)' : '(任意)'}選択してください
                </p>
              )}
              <div className="select-travelers__selected-list__right">
                {this.state.showOrganizationBasesList ? '▲' : '▼'}
              </div>
            </div>
            {this.state.showOrganizationBasesList && (
              <OrganizationBasesList
                organizationBases={this.props.organizationBases}
                onSelect={this.handleOrganizationBaseSelect}
              />
            )}
          </div>
        </div>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export default SelectOrganizationBases;
