import type { BulkActionAttr } from '@this/src/components/organization/types';

export const UPSERT_FORMAT: BulkActionAttr[] = [
  {
    name: 'exic_number',
    summary: 'EXIC会員ID',
    example: '半角数字10桁',
    required: true
  },
  {
    name: 'password',
    summary: 'EXICパスワード',
    example: '半角数字記号4-8桁',
    required: true
  }
];
