TripReport = require('./trip_report/trip_report/index').default

module.exports = class TripReportList
  @withTripList: (rawItems, tripList) ->
    items = _.map rawItems, (item) ->
      trip = _.find tripList.list, (t) -> t.id == item.trip_id
      _.merge item, { trip: trip }
    new @(items)

  constructor: (rawItems) ->
    @items = _.map rawItems, (raw) -> new TripReport(raw)

  selectedReports: ->
    _.filter @items, (report) -> report.selected

  selectedExpenses: ->
    _.flatten(_.map(@selectedReports(), (report) -> report.selectedExpenses()))

  selectedTypeSummary: ->
    summary = {}
    total = 0
    for expense in @selectedExpenses()
      if expense.type && expense.type.length > 0
        summary[expense.type] ||= 0
        summary[expense.type] += expense.price
      total += expense.price
    summary['合計'] = total
    for k in _.keys(summary)
      summary[k] = formatPrice(summary[k])
    summary

  toCreateExpenseApplicationParams: ->
    trip_reports: _.map(@selectedReports(), (report) -> report.toCreateExpenseApplicationParams())

  totalPrice: ->
    _.sum(_.map(@items, (e) -> e.totalPrice()))
